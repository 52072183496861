import React from 'react';
import { Col, Row } from 'reactstrap';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import { usePengeluaran } from './pengeluaranFunction';

const PengeluaranCetak = (props) => {
    const { isLoading, pengeluaranDetail } = usePengeluaran({tipe: 'print'})
    return(
        <div className='laporan-potrait bukti-pengeluaran'>
            { isLoading && <LoaderComponent/>}
            <div className='laporan'>
                <div className="header">                
                    <Row>
                        <Col xs={12}>
                            <div className="text-center title">
                                BUKTI PENGELUARAN KAS
                            </div>
                            <div className="text-center nomor-bukti-pengeluaran">
                                NOMOR { pengeluaranDetail && pengeluaranDetail.nomor_transaksi }
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="body">
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>Tanggal</td>
                                <td>:</td>
                                <td>{ pengeluaranDetail && pengeluaranDetail.tanggal_display}</td>
                            </tr>
                            <tr>
                                <td>Jumlah</td>
                                <td>:</td>
                                <td>{ pengeluaranDetail && pengeluaranDetail.jumlah_debet && pengeluaranDetail.jumlah_debet.toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td>Terbilang</td>
                                <td>:</td>
                                <td>{ pengeluaranDetail && pengeluaranDetail.terbilang}</td>
                            </tr>
                            <tr>
                                <td>Keperluan</td>
                                <td>:</td>
                                <td></td>
                            </tr>
                            { pengeluaranDetail && pengeluaranDetail.detail && pengeluaranDetail.detail.filter(x => x.debet > 0).map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td></td>
                                        <td></td>
                                        <td>{post.discription} - Rp.{post.debet.toLocaleString()}</td>
                                    </tr>
                                )  
                            }) }
                        </tbody>
                    </table>
                    <div className="row ttd-pengeluaran">
                        <Col xs={6}>
                            <div className="header-ttd text-center">
                                Kasir
                            </div>
                            <div className="nama-ttd text-center">
                                { pengeluaranDetail && pengeluaranDetail.uid_display}
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="header-ttd text-center">
                                Penerima
                            </div>
                            <div className="nama-ttd text-center">
                                { pengeluaranDetail && pengeluaranDetail.penerima}
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PengeluaranCetak;