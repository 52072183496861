import { useCallback, useEffect, useState } from "react";
import config from "../../../config";
import { useGlobalFuntction } from "../../../generalFunction/globalFunction";
import api from "../../../services/api";
import Moment from 'moment'
import * as XLSX from "xlsx";

export function useResumeOutlet(props){
    const { 
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, 
    } = useGlobalFuntction();
    const date = new Date();
    const fetchTipe = props && props.fetchTipe ? props.fetchTipe : '';
    const [formData, setFormData] = useState({
        outlet : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select', 
            label : 'Outlet', 
            name : 'outlet',
            readOnly : false,
            obj : []
        },
        tanggal : {
            value : date,
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'date', 
            label : 'Tanggal', 
            name : 'tanggal',
            readOnly : false,
        },
        shift : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select', 
            label : 'Shift', 
            name : 'shift',
            readOnly : false,
            obj : []
        },
    })
    
    const fetchShift = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.shift}`).then(res => res.data);
            let tmp = [{value : '', label : '-- Semua Shift --'}]
            if (res){   
                res.results.map(post => tmp.push({value: post.id, label : post.nama}))
            }
            setFormData(formData => ({...formData,
                shift : {...formData.shift, obj : tmp}
            }));
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Unable to fetch Data Shift', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setAlertValue, setIsLoading]);

    useEffect(() => {
        if (fetchTipe === 'header'){
            fetchShift();
        }
    },[fetchShift, fetchTipe])

    const fetchOutlet = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.masterOutlet}`).then(res => res.data);
            let tmp = [{value : '', label : '-- Semua Outlet --'}]
            if (res){   
                res.results.map(post => tmp.push({value: post.id, label : post.nama}))
            }
            setFormData(formData => ({...formData,
                outlet : {...formData.outlet, obj : tmp}
            }));
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Unable to fetch Data Outlet', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setAlertValue, setIsLoading]);

    useEffect(() => {
        if (fetchTipe === 'header'){
            fetchOutlet();            
        }
    },[fetchOutlet, fetchTipe])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({...formData, [name] : {...formData[name], value : value}}));
        
        let outlet = formData.outlet.value
        let shift = formData.shift.value
        if (name === 'shift'){
            shift = value;
            if (value !== ''){
                fetchDetailShift(value);
            }else{
                setInfo(info => ({...info, shift_id: '', shift_name: 'Semua Shift'}))
            }
        }
        if (name === 'outlet'){
            if (value !== ''){
                fetchDetailOutlet(value);
            }else{
                setInfo(info => ({...info, outlet_id: '', outlet_name: 'Semua Outlet'}))
            }
            outlet = value;
        }
        localStorage.removeItem('dataLaporanPenerimaan')
        let tmp = {
            tanggal : Moment(formData.tanggal.value).format('YYYY-MM-DD'),
            outlet : outlet,
            shift : shift
        }
        localStorage.setItem('dataLaporanPenerimaan', JSON.stringify(tmp));
        fetchDetailLaporan(tmp.tanggal, shift, outlet);
    }
    
    const handleChangeDate = (e, name) => {
        setFormData(formData => ({...formData, [name] : {...formData[name], value : e}}));

        let outlet = formData.outlet.value
        let shift = formData.shift.value        
        localStorage.removeItem('dataLaporanPenerimaan')
        let tmp = {
            tanggal : Moment(e).format('YYYY-MM-DD'),
            outlet : outlet,
            shift : shift
        }
        localStorage.setItem('dataLaporanPenerimaan', JSON.stringify(tmp))
        fetchDetailLaporan(tmp.tanggal, shift, outlet);
    }
    
    const handlePrint = () => {
        window.open(`#/laporan/cetak/resume-outlet`, "_blank")        
    }

    // ========================== cetak =========================
    const [info, setInfo] = useState({
        outlet_id : '',
        outlet_name : "Semua Outlet",
        tanggal : Moment(date).format('YYYY-MM-DD'),
        shift_id : '',
        shift_name : "Semua Shift",
        total : 0,
        cash : 0,
        piutang : 0,
        deposite : 0,
        bank : 0,
        debit : 0,
        emoney : 0,        
    });
    const [detailLaporan, setDetailLaporan] = useState([]);

    const fetchDetailLaporan = useCallback(async(tanggal='', shift='', outlet='') => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.penjualanResumeOutletKasir}?tanggal=${tanggal}&shift=${shift}&outlet=${outlet}`).then(res => res.data);
            let total = 0;
            let cash = 0
            let piutang = 0
            let deposite = 0
            let bank = 0
            let debit = 0
            let emoney = 0
            let tmp = [];
            
            if (res){
                res.results.map(post => {
                    tmp.push(post);
                    total += parseInt(post.total);
                    cash += parseInt(post.cash_value)
                    piutang += parseInt(post.piutang_value)
                    deposite += parseInt(post.deposite_value)
                    bank += parseInt(post.bank_value)
                    debit += parseInt(post.debit_value)
                    emoney += parseInt(post.emoney_value)
                    return true;
                })
            }            
            setInfo(info => ({...info, 
                total : total, 
                cash : cash,
                piutang : piutang,
                deposite : deposite,
                bank : bank,
                debit : debit,
                emoney : emoney,
            }));

            setDetailLaporan(tmp);
            if (tanggal !== ''){
                let myTanggal = Moment(tanggal, 'YYYY-MM-DD').toDate()
                myTanggal = Moment(myTanggal).format('DD-MM-YYYYY');
                setInfo(info => ({...info, tanggal : myTanggal}))
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Unable to fetch Data Outlet', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setAlertValue, setIsLoading])

    const fetchDetailOutlet = useCallback(async(id=0) => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.masterOutlet}${id}/`).then(res => res.data);
            if (res){
                setInfo(info => ({...info,
                    outlet_name : res.results.nama,
                    outlet_id : res.results.id
                }))
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Unable to fetch Data Outlet', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setIsLoading, setAlertValue])
    
    const fetchDetailShift = useCallback(async(id=0) => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.shift}${id}/`).then(res => res.data);
            if (res){
                setInfo(info => ({...info,
                    shift_name : res.results.nama,
                    shift_id : res.results.id
                }))
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Unable to fetch Data Outlet', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setIsLoading, setAlertValue])


    useEffect(() => {        
        let myTanggal = new Date()
        let tanggal = Moment(myTanggal).format('YYYY-MM-DD')
        let shift = '';
        let outlet = '';
        if (fetchTipe === 'detail'){
            const dataLocal = localStorage.getItem('dataLaporanPenerimaan') || null;
            // let tanggal = ''
            
            
            if (dataLocal){           
                let resDataLocal = JSON.parse(dataLocal);
                tanggal = resDataLocal.tanggal;
                outlet  = resDataLocal.outlet;
                shift = resDataLocal.shift;            
            }
            fetchDetailLaporan(tanggal, shift, outlet);

            if (shift !== ''){
                fetchDetailShift(shift);
            }
            if (outlet !== ''){
                fetchDetailOutlet(outlet);
            }            
        }else if(fetchTipe === 'header'){
            fetchDetailLaporan(tanggal, shift, outlet);
        }
    },[fetchDetailLaporan, fetchTipe, fetchDetailShift, fetchDetailOutlet]);
    
    const handleExport = async() => {
        setIsLoading(true);
        try{
            let tanggal = Moment(formData.tanggal.value).format('YYYY-MM-DD')
            const res = await api.get(`${config.endPoint.penjualanResumeOutletKasir}?tanggal=${tanggal}&shift=${formData.shift.value}&outlet=${formData.outlet.value}`).then(res => res.data);                        
            let tmp = [];

            var ws = XLSX.utils.aoa_to_sheet([
                ["Laporan Resume Outlet"],
                ["Nama Outlet",null,  info.outlet_name],
                ["Tanggal", null, info.tanggal],
                ["Shift", null, info.shift_name],
                [""]
            ]);
            
            const header = [
                'Nomor',
                'Outlet',
                'Nomor Transaksi',
                'Konsumen',
                'Cash',
                'Piutang',
                'Deposite',
                'Bank',
                'Debit',
                'Emoney',
                'Total',
                'PIC'
            ]
            if (res){
                res.results.map((post, index) => {
                    tmp.push({
                        'Nomor' : index + 1,
                        'Outlet': post.outlet_display,
                        'Nomor Transaksi' : post.no_transaksi,
                        'Konsumen' : post.konsumen_name,
                        'Cash' : post.cash_value,
                        'Piutang' : post.piutang_value,
                        'Deposite' : post.deposite_value,
                        'Bank' : post.bank_value,
                        'Debit' : post.debit_value,
                        'Emoney' : post.emoney_value,
                        'Total' : post.total,
                        'PIC' : post.pic_name
                    });
                    return true;
                })
            }            
            // const worksheet = XLSX.utils.json_to_sheet(ws, tmp);
            XLSX.utils.sheet_add_json(ws, tmp, {header:header, origin:-1});
            const workbook = XLSX.utils.book_new();            
            XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
            //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
            //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
            XLSX.writeFile(workbook, "resume-outlet.xlsx");
            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Unable to fetch Data Outlet', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }

        
    }
    
    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, formData, handleChange, handleChangeDate, info, detailLaporan,
        handlePrint, handleExport        
    }
}