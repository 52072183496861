import React from 'react';
import LapPenerimaanComp from './LapPenerimaanComp';

const LapPenerimasnCetak = () => {
    return (
        <div className='laporan-potrait'>
            <LapPenerimaanComp/>
        </div>
    )
}
export default LapPenerimasnCetak;