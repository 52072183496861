import React, { Fragment } from 'react';
import { Col, Row, Table } from 'reactstrap';
import config from '../../../config';


const LabaRugiPrint = (props) => {
    let info = JSON.parse(localStorage.getItem(config.localStorageKey.LR_DATA)) || null;
    return(
        <div className='laporan-lanscape' style={{background: '#ffffff'}}>
            {/* <div className='laporan'> */}

                <div className="header" style={{marginBottom: 20}}>
                    <Row>
                        <Col xs={12}>
                            <div className="text-center title">
                                Laporan Laba Rugi
                            </div>
                            <div className="text-center title">
                                { info && info.data && info.data.perusahaanName}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className="text-center tanggal-cetak">
                                <i>Periode { info && info && info.startDate} s.d { info && info && info.endDate} </i>
                            </div>
                        </Col>
                    </Row>                
                </div>
                <div className="body">
                    <Row>
                        <Col xs={12}>
                            <Table className='table-small table-bordered table-center table-laporan'>
                                <thead>
                                    <tr>
                                        <th>Nomor Akun</th>
                                        <th>Nama Akun</th>
                                        <th>Amount</th>                                        
                                    </tr>                                    
                                </thead>
                                <tbody>
                                    { info && info.data && info.data.map((parent, parentIndex) => {
                                        return(
                                            <Fragment key={parentIndex}>
                                                <tr key={parentIndex}>
                                                    <td style={{fontWeight: 'bold'}} className='text-center'>{parent.parent_noakun}</td>
                                                    <td style={{fontWeight: 'bold'}} colSpan={parent.show_sub_total ? 2 : 0}>{parent.parent_nmakun}</td>
                                                    { !parent.show_sub_total &&
                                                        <td style={{fontWeight: 'bold'}} className='text-right'>{parent.total ? parent.total.toLocaleString() : 0}</td>
                                                    }
                                                </tr>
                                                { parent.child.map((post, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <td className='text-center'>{post.noakun}</td>
                                                            <td>{post.nmakun}</td>
                                                            <td className='text-right'>{post.totalmtd ? post.totalmtd.toLocaleString() : 0}</td>
                                                        </tr>
                                                    )
                                                })}
                                                { parent.show_sub_total &&
                                                    <tr key={parentIndex+1}>
                                                        <td></td>
                                                        <td style={{fontWeight: 'bold'}}>TOTAL {parent.parent_nmakun}</td>
                                                        <td style={{fontWeight: 'bold'}} className='text-right'>{parent.total ? parent.total.toLocaleString() : 0}</td>
                                                    </tr>
                                                }
                                                {/* { parent.show_sub_total && <tr><td colSpan={3} style={{height: 30}}></td></tr> } */}
                                            </Fragment>
                                        )
                                    })}
                                </tbody>                            
                            </Table>
                        </Col>
                    </Row>
                </div>
            {/* </div> */}
        </div>
    )
}
export default LabaRugiPrint;