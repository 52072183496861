import React, { useCallback, useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Col, Row, Table } from 'reactstrap';
import config from '../../../config';
import { useGlobalFuntction } from '../../../generalFunction/globalFunction';
import api from '../../../services/api';
import Moment from 'moment';
import LoaderComponent from '../../../containers/Components/LoaderComponent';

const StockOpenamePrint = () => {
    const [listData, setListData] = useState([]);
    const { isLoading, setIsLoading, pageValue } = useGlobalFuntction();
    const date = new Date();
    const [lokasiValue, setLokasiValue] = useState(null);

    const fetchData = useCallback(async() => {
        setIsLoading(true);
        try{
            let lokasi_id = localStorage.getItem(config.localStorageKey.STOCK_OPNAME_PARAMS);
            const res = await api.get(`${config.endPoint.produk}?lokasi_id=${lokasi_id}&tipe_produk=dibeli`, { params : {limit: 3000, offset: 0}}).then(res => res.data);
            if (res){
                setListData(res.results);
            }
            setIsLoading(false);            
        }catch(error){
            setIsLoading(false);
        }
    },[setIsLoading])

    useEffect(() => {
        fetchData();
    },[fetchData])

    const fetchLokasi = useCallback(async() => {
        setIsLoading(true);
        try{
            let lokasi_id = localStorage.getItem(config.localStorageKey.STOCK_OPNAME_PARAMS);
            const res = await api.get(`${config.endPoint.lokasi}${lokasi_id}/`).then(res => res.data);
            if (res){
                setLokasiValue(res.results)                
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
        }
    },[setIsLoading])

    useEffect(() => {
        fetchLokasi();
    },[fetchLokasi])

    return (
        <Fragment>
            { isLoading && <LoaderComponent/>}
            <div className='laporan-potrait'>
                <div className='laporan'>
                    <div className="header">
                        <Row>
                            <Col xs={12}>
                                <div className="text-center title">
                                    Laporan Stock Opname
                                </div>
                                <div className="text-center title">
                                    { lokasiValue && lokasiValue.nama }
                                </div>
                                <div className="text-center title">
                                    Tanggal Cetak { Moment(date).format('DD-MM-YYYY')}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="body">
                        <Table className='table-small table-bordered table-center table-laporan table-striped'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nama Produk</th>
                                    <th>Barcode</th>
                                    <th>Kategori</th>                                    
                                    <th>Harga Jual</th>
                                    <th>Harga Beli</th>
                                    <th>Saldo</th>
                                    <th>Actual</th>
                                </tr>
                            </thead>
                            <tbody>
                                { listData.map((post, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className="text-right">{pageValue.offset+1+index}</td>
                                            <td>{post.nama}</td>
                                            <td>{post.barcode}</td>
                                            <td>{post.kategori_display && post.kategori_display.nama}</td>                                            
                                            <td className='text-right'>{post.harga_jual.toLocaleString()}</td>
                                            <td className='text-right'>{post.harga_beli.toLocaleString()}</td>                                            
                                            <td className='text-right'>{post.qty ? post.qty.saldo : 0}</td>
                                            <td></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default StockOpenamePrint;