import React from 'react';
import { Row, Col, Table,  } from 'reactstrap';
import config from '../../../config';

const NeracaPrint = (props) => {
    let info = JSON.parse(localStorage.getItem(config.localStorageKey.NR_DATA)) || null;
    
    return(
        <div className='laporan-lanscape' style={{background: '#ffffff'}}>
                <div className="header" style={{marginBottom: 20}}>
                    <Row>
                        <Col xs={12}>
                            <div className="text-center title">
                                Laporan Neraca
                            </div>
                            <div className="text-center title">
                                { info && info.data && info.data.perusahaanName}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className="text-center tanggal-cetak">
                                <i>Periode { info &&  info.bulanName}  { info &&  info.tahun} </i>
                            </div>
                        </Col>
                    </Row>                
                </div>
                <div className="body">
                    <Row>
                        <Col lg={6} xs={6}>
                            <Table className='small table-bordered table-center table-sm-padding table-striped table-header-withbackground'>
                                <thead>
                                    <tr>                                        
                                        <th>Nomor Akun</th>
                                        <th>Nama Akun</th>
                                        <th>Result</th>                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    { info && info.listDebet && info.listDebet.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td style={{fontWeight: post.is_parent ? 'bold' : ''}}>{post.noakun && post.noakun}</td>
                                                <td style={{fontWeight: post.is_parent ? 'bold' : ''}}>{post.nmakun && post.nmakun}</td>
                                                <td style={{fontWeight: post.is_parent ? 'bold' : ''}} className='text-right'>{post.total ? (post.is_parent || post.total === 0) ? '' : post.total.toLocaleString() : ''}</td>
                                            </tr>
                                        )
                                    })}
                                    { info && info.listDebet.length > 0 &&
                                        <tr>
                                            <td colSpan={2} style={{fontWeight: 'bold'}} className='text-center'>BALANCE</td>
                                            <td style={{fontWeight: 'bold'}} className='text-right'>{info && info.totalKredit && info.totalDebet.toLocaleString()}</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                        <Col lg={6} xs={6}>
                            <Table className='small table-bordered table-center table-sm-padding table-striped table-header-withbackground'>
                                <thead>
                                    <tr>                                        
                                        <th>Nomor Akun</th>
                                        <th>Nama Akun</th>
                                        <th>Result</th>                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    { info && info.listKredit && info.listKredit.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td style={{fontWeight: post.is_parent ? 'bold' : ''}}>{post.noakun}</td>
                                                <td style={{fontWeight: post.is_parent ? 'bold' : ''}}><div style={{visibility: post.nmakun === '-' ? 'hidden' : 'visible'}}>{post.nmakun}</div></td>
                                                <td style={{fontWeight: post.is_parent ? 'bold' : ''}} className='text-right'>{(post.is_parent || post.total === 0) ? '' : post.total.toLocaleString()}</td>
                                            </tr>
                                        )
                                    })}
                                    { info && info.listKredit.length > 0 &&
                                        <tr>
                                            <td colSpan={2} style={{fontWeight: 'bold'}} className='text-center'>BALANCE</td>
                                            <td style={{fontWeight: 'bold'}} className='text-right'>{info && info.totalKredit && info.totalKredit.toLocaleString()}</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
        </div>
    )
}
export default NeracaPrint