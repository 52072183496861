import React, { Fragment, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router";
import config from "../../../config";
import LoaderComponent from "../../../containers/Components/LoaderComponent";
import { useGlobalFuntction } from "../../../generalFunction/globalFunction";
import api from "../../../services/api";
import '../../../style/cvStyle.css';

import MailIcon from '../../../assets/img/icon/mail-icon.png';
import PhoneIcon from '../../../assets/img/icon/phone-icon.png';
import HomeIcon from '../../../assets/img/icon/home-icon.png';
import MonitorIcon from '../../../assets/img/icon/monitor-icon.png';
import SarjanaIcon from '../../../assets/img/icon/sarjana-icon.png';
// import SkillIcon from '../../../assets/img/icon/skiils-icon.png';

const PegawaiCVPrint = (props) => {
    const {pegawaiId} = useParams();    
    const [pegawaiInfo, setPegawaiInfo] = useState(null);
    const { isLoading, setIsLoading} = useGlobalFuntction();
    
    const fetchPegawaiInfo = useCallback(async(id=0) => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.pegawai}${pegawaiId}/`).then(res => res.data);
            if (res){
                setPegawaiInfo(res.results);
            }            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);            
        }
    }, [pegawaiId, setIsLoading])

    useEffect(() => {
        fetchPegawaiInfo();
    },[fetchPegawaiInfo])

    return(
        <Fragment>
            { isLoading && <LoaderComponent/>}
            <div className='containeri'>
                <div className="container-left">
                    <div className="primary-title">CURRICULUM VITAE</div>
                    <div className="img-container">
                        <div className="striped">
                            <img src={pegawaiInfo && pegawaiInfo.foto} alt="foto"/>
                        </div>                    
                    </div>
                    <div className="employee-name">
                        {pegawaiInfo && pegawaiInfo.kontak_display.nama.toUpperCase()}
                    </div>
                    <div className="contact-container">
                        <div className="title">CONTACT</div>
                        <ul className="ul-contact">
                            <li>
                                <div className="icon-container">
                                    <img alt="icon" src={MailIcon} className='icon' />
                                </div> 
                                <div className='icon-content'>{pegawaiInfo && pegawaiInfo.kontak_display.email}</div>
                            </li>
                            <li>
                                <div className="icon-container">
                                    <img alt="icon" src={PhoneIcon} className='icon' />
                                </div> 
                                <div className='icon-content'>{pegawaiInfo && pegawaiInfo.kontak_display.hp}</div>
                            </li>
                            <li>
                                <div className="icon-container">
                                    <img alt="icon" src={HomeIcon} className='icon' />
                                </div> 
                                <div className='icon-content'>{pegawaiInfo && pegawaiInfo.kontak_display.alamat}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="container-right">                
                    <div className="experience-container">
                        <div className="title-container">
                            <div className="icon-container">
                                <img alt="icon" src={MonitorIcon} className="icon" />
                            </div>
                            <div className="label-title">BIODATA</div>                        
                        </div>
                        <div className="experience-content">
                            <ul className='ul-experience'>
                                <li>
                                    <div className='experience-perusahaan'>NIP</div>
                                    <div className='experience-tahun'>{pegawaiInfo && pegawaiInfo.nip}</div>
                                </li>
                                <li>
                                    <div className='experience-perusahaan'>Jenis Kelamin</div>
                                    <div className='experience-tahun'>{pegawaiInfo && pegawaiInfo.kontak_display && pegawaiInfo.kontak_display.jk_display}</div>
                                </li>
                                <li>
                                    <div className='experience-perusahaan'>Agama</div>
                                    <div className='experience-tahun'>{pegawaiInfo && pegawaiInfo.agama_display}</div>
                                </li>
                                <li>
                                    <div className='experience-perusahaan'>Tempat & Tanggal Lahir</div>
                                    <div className='experience-tahun'>{pegawaiInfo && pegawaiInfo.tempat_lahir } & { pegawaiInfo && pegawaiInfo.kontak_display && pegawaiInfo.kontak_display.tanggal_lahir_display}</div>
                                </li>
                                <li>
                                    <div className='experience-perusahaan'>Alamat</div>
                                    <div className='experience-tahun'>{pegawaiInfo && pegawaiInfo.kontak_display && pegawaiInfo.kontak_display.alamat}</div>
                                </li>
                                <li>
                                    <div className='experience-perusahaan'>HP</div>
                                    <div className='experience-tahun'>{pegawaiInfo && pegawaiInfo.kontak_display && pegawaiInfo.kontak_display.hp}</div>
                                </li>
                                <li>
                                    <div className='experience-perusahaan'>Email</div>
                                    <div className='experience-tahun'>{pegawaiInfo && pegawaiInfo.kontak_display && pegawaiInfo.kontak_display.email}</div>
                                </li>
                                <li>
                                    <div className='experience-perusahaan'>BPJS Ketenagakerjaan</div>
                                    <div className='experience-tahun'>{pegawaiInfo && pegawaiInfo.bpjs_ketanagakerjaan_display}</div>
                                </li>
                                <li>
                                    <div className='experience-perusahaan'>BPJS Kesehatan</div>
                                    <div className='experience-tahun'>{pegawaiInfo && pegawaiInfo.bpjs_kesehatan_display}</div>
                                </li>
                                <li>
                                    <div className='experience-perusahaan'>Department</div>
                                    <div className='experience-tahun'>{pegawaiInfo && pegawaiInfo.department_display}</div>
                                </li>
                                <li>
                                    <div className='experience-perusahaan'>Jabatan</div>
                                    <div className='experience-tahun'>{pegawaiInfo && pegawaiInfo.jabatan_display}</div>
                                </li>
                                <li>
                                    <div className='experience-perusahaan'>Tanggal Mulai Kerja</div>
                                    <div className='experience-tahun'>{pegawaiInfo && pegawaiInfo.start_date_display}</div>
                                </li>
                                <li>
                                    <div className='experience-perusahaan'>Bank</div>
                                    <div className='experience-tahun'>{pegawaiInfo && pegawaiInfo.kontak_display && pegawaiInfo.kontak_display.bank}</div>
                                </li>
                                <li>
                                    <div className='experience-perusahaan'>Nomor Rekening</div>
                                    <div className='experience-tahun'>{pegawaiInfo && pegawaiInfo.kontak_display && pegawaiInfo.kontak_display.nomor_rekening}</div>
                                </li>
                                <li>
                                    <div className='experience-perusahaan'>Nama Rekening</div>
                                    <div className='experience-tahun'>{pegawaiInfo && pegawaiInfo.kontak_display && pegawaiInfo.kontak_display.nama_rekening}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="study-container">
                        <div className="title-container">
                            <div className="icon-container">
                                <img alt="icon" src={SarjanaIcon} className="icon" />
                            </div>
                            <div className="label-title">PENDIDIKAN</div>
                        </div>
                        <div className="study-content">
                            <div className="formal-title" style={{marginBottom: 10}}>FORMAL</div>
                            <ul className='ul-study'>
                                { pegawaiInfo && pegawaiInfo.list_pendidikan.map((post, index) => {
                                    if (post.is_formal){
                                        return(
                                            <li key={index}>
                                                <div className='universitas'>{post.lulusan}</div>
                                                <div className='fakultas-container'>
                                                    <div className='fakultas'>{post.nama_gelar}</div>
                                                    <div className="tahun-lulus">{post.tahun_lulus}</div>
                                                </div>
                                            </li>
                                        )
                                    }
                                    return true;
                                })}                                
                            </ul>
                            <div className="formal-title" style={{marginTop: 20, marginBottom: 10}}>NON FORMAL</div>
                            <ul className='ul-study'>
                                { pegawaiInfo && pegawaiInfo.list_pendidikan.map((post, index) => {
                                    if (!post.is_formal){
                                        return(
                                            <li key={index}>
                                                <div className='universitas'>{post.lulusan}</div>
                                                <div className='fakultas-container'>
                                                    <div className='fakultas'>{post.nama_gelar}</div>
                                                    <div className="tahun-lulus">{post.tahun_lulus}</div>
                                                </div>
                                            </li>
                                        )
                                    }
                                    return true;
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default PegawaiCVPrint