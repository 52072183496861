import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import config from '../config';
import api from '../services/api';

export function  useGlobalFuntction(){
    const [isLoading, setIsLoading] = useState(false);
    const { roles } = useSelector(state => state);
    const [alertValue, setAlertValue] = useState({
        show : false,
        text : '',
        color : 'danger'
    });
    const [modalValue, setModalValue] = useState({
        show: false,
        tipe: '',
        content : 'list',
        text : '',
        title : '',
        id : 0,
        params : null
    })

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({...alertValue, show: false, text : ''}))
    }

    const [pageValue, setPageValue] = useState({
		page : 1,
		search : '',
		offset : 0,
		obj : [],
        cabang_id : '',
        cabang_obj : [],
        tipe_harga_id : '',
        tipe_harga_obj : [],
        lastPage : 0,
        tipe_kontak : '',
        status_active : '',
        start_date : null,
        end_date: null,
        lokasi_id : '',
        lokasi_tujuan : '',
        lokasi_asal: '',
        perusahaan : 1,
        perusahaanObj : [],
        myIndex: 0,
        outlet : '',
        outletObj : [],
        orderBy : '',
        orderType : 'asc',
        expired_deposite : '',
        expired_deposite_obj : [
            {value : '', label : '-- Tampilkan Semua --'},
            {value : '1', label : 'Sudah Expired'},
            {value : '2', label : 'Expired bulan ini'},
        ],
        tahun : '',
        tahunObj : [],
        statusAktif : '',
        statusAktifObj : [
            {value : '', label : '-- Tampilkan Semua --'},
            {value : 1, label : 'Aktif'},
            {value : 2, label : 'Resign'},
            {value : 3, label : 'Diberhentikan'},
        ],
	});

    const handleCloseModal = () => {
        setModalValue({
            show: false,
            tipe: '',
            content : 'list',
            text : '',
            title : '',
        })
    }

    const fetchAccount = async(nature='all') => {
        setIsLoading(true);        
        let listAccount = [];
        try{
            let url = `${config.endPoint.accountByGroup}?`
            if (nature !== 'all'){
                url = url + `&nature=${nature}`
            }
            const res = await api.get(url).then(res => res.data);
            setIsLoading(false);
            if (res){
                listAccount = res.results;
            }
        }catch(error){  
            setIsLoading(false);            
        }
        return listAccount;
    }

    const fetchPerusahaan = useCallback(async() => {
        setIsLoading(true);                
        try{
            let url = `${config.endPoint.perusahaan}?`            
            const res = await api.get(url).then(res => res.data);
            if (res){
                let tmp = [];
                if (res.results.length > 1){
                    tmp.push({label : '-- Pilih Perusahaan --', value : ''})
                }
                res.results.map(post => tmp.push({label : post.nama, value : post.id}))

                let perush = '';
                if (tmp.length > 0){
                    if (tmp.length === 1){
                        perush = res.results[0].id
                    }else{
                        perush = tmp[0].value
                    }
                    // perush = 1;
                }

                setPageValue(pageValue => ({...pageValue, 
                    perusahaanObj : tmp,
                    perusahaan : perush
                }))
            }
            setIsLoading(false);            
        }catch(error){  
            setIsLoading(false);            
        }
    }, []);

    useEffect(() => {
        fetchPerusahaan();
    }, [fetchPerusahaan])

    const fetchProdukSatuan = async(produk_id=null, default_satuan='yes') => {
        setIsLoading(true);        
        let listSatuan = [];
        try{
            const res = await api.get(`${config.endPoint.satuan}?produk_id=${produk_id}&default_satuan=${default_satuan}`).then(res => res.data);
            setIsLoading(false);
            if (res){
                listSatuan = res.results;
            }
        }catch(error){  
            setIsLoading(false);
        }
        return listSatuan;
    }
    
   
        
    const getPermission = (permissionName) => {
        if (roles.is_superuser || roles.roles.includes(permissionName)){
            return true;
        }else{                        
            return false
        }
    }


    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, fetchAccount, fetchProdukSatuan, getPermission,
    }
}