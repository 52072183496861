import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import config from '../../../config';
import PerusahaanComp from '../../../containers/Components/PerusahaanComp';
import api from '../../../services/api';
// import * as penjualanService from '../../../services/penjualanService';

import '../../../style/strukStyle.scss';


const StrukDepositePrint = (props) => {
    const depositeDetailId = localStorage.getItem('depositeDetailId') || 0;
    const [depositeDetailInfo, setDepositeDetailInfo] = useState(null);    

    const fetchData = useCallback(async() => {
        try{
            const res = await api.get(`${config.endPoint.depositeDetail}${depositeDetailId}/`).then(res => res.data);
            setDepositeDetailInfo(res.results);
        }catch(error){
            return;
        }
    },[depositeDetailId])

    useEffect(() => {
        fetchData();
    },[fetchData])    
    
    

    return(
        <Fragment>
            <div className='strukprint-container'>
                { depositeDetailInfo && depositeDetailInfo.perusahaan &&
                    <PerusahaanComp
                        perusahaanId={depositeDetailInfo ? depositeDetailInfo.perusahaan : 0}
                    />
                }
                <div className="text-center title">BUKTI DEPOSITE</div>
                <div className="text-center desc" style={{marginBottom: '10px'}}>{depositeDetailInfo && depositeDetailInfo.nomor_transaksi}</div>
                <Row>
                    <Col xs={12}>
                        <div className='text-center kasir-name'>Kasir : {depositeDetailInfo && depositeDetailInfo.pic_name}</div>
                        <div className='text-center tanggal-penjualan'>{depositeDetailInfo && depositeDetailInfo.tanggal_jam_display}</div>
                    </Col>                    
                </Row>
                <div style={{borderBottom: '1px solid #000', marginBottom: '5px'}}></div>
                <div className="harga">
                    <div className='kasir-name'>Jumlah Deposite : Rp.{depositeDetailInfo && depositeDetailInfo.ammdb && depositeDetailInfo.ammdb.toLocaleString()}</div>
                </div>
                <div className="harga">
                    <div className='kasir-name'>Terbilang : { depositeDetailInfo && depositeDetailInfo.terbilang }</div>
                </div>
                <div className="konsumen">
                    <div className='kasir-name'>Nama Konsumen : { depositeDetailInfo && depositeDetailInfo.konsumen_name}</div>
                </div>
                { depositeDetailInfo && depositeDetailInfo.tgl_expired_display !== '' &&
                    <div className="konsumen">
                        <div className='kasir-name'>Tanggal Berakhir : { depositeDetailInfo && depositeDetailInfo.tgl_expired_display}</div>
                    </div>
                }
                <div style={{borderBottom: '1px solid #000', marginBottom: '5px'}}></div>
                <div className="text-center">
                    <div className='text-center kasir-name'>Ttd</div>
                    <div style={{marginTop: 45}}/>
                    <div className='text-center kasir-name'>Kasir : {depositeDetailInfo && depositeDetailInfo.pic_name}</div>
                </div> 
            </div>
        </Fragment>
    )
}
export default StrukDepositePrint