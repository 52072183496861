import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import config from '../../config';
import api from '../../services/api';
// import * as masterService from '../../services/masterService'

const PerusahaanComp = (props) => {
    const perusahaanId = props && props.perusahaanId ? props.perusahaanId : 0
    const [compInfo, setCompInfo] = useState({
        nama : '',
        alamat : '',
        kota : '',
        kecamatan : '',
        telp : ''
    });
    const fetchCompany = useCallback(async(id) => {
        try{
            const res = await api.get(`${config.endPoint.perusahaan}${id}/`).then(res => res.data);            
            setCompInfo(compInfo => ({...compInfo,
                nama : res.results.nama,
                alamat : res.results.alamat,
                telp : res.results.telp,
                kecamatan : res.results.kecamatan_desa_display,
                kota : res.results.kota_display,
            }));        
        }catch(error){
            return
        }
    },[])

    useEffect(() => {
        if (perusahaanId !== 0){
            fetchCompany(perusahaanId);
        }
    },[fetchCompany, perusahaanId])

    return(
        <div className={props.className ? props.className : ''}>
            <Row>
                <Col xs={12}>
                    <div className="company-name">
                        {compInfo.nama}
                    </div>
                    <div className="company-address">                            
                        { compInfo.alamat }
                    </div>
                    <div className="company-city">
                        { compInfo.kecamatan }
                    </div>
                    <div className="company-city">
                        { compInfo.kota }
                    </div>
                    <div className="company-phone">
                        { compInfo.telp }
                    </div>
                </Col>                    
            </Row>
        </div>
    )
}
export default PerusahaanComp