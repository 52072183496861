import React from 'react';
import { useParams } from 'react-router';
import Moment from 'moment';
import { Alert, Col, Row, Table } from 'reactstrap';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import { usePiutang } from './piutangFunction';

const PiutangCetak = (props) => {
    const { type, perusahaanParams } = useParams();
    const { isLoading, alertValue, handleCloseAlert, perusahaan, listData, totalHutang} = usePiutang({view: type, perusahaan : perusahaanParams, fetchTipe : 'print'})
    const date = new Date();    
    return(
        <div className='laporan-potrait'>
            <div className='laporan'>
                { isLoading && <LoaderComponent/>}
                <Alert isOpen={alertValue.show} color={alertValue.color} toggle={handleCloseAlert}>
                    {alertValue.text}
                </Alert>
                <div className="header">                
                    <Row>
                        <Col xs={12}>
                            <div className="text-center title">
                                Laporan Piutang by {type}
                            </div>
                            <div className="text-center title">
                                { perusahaan }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className="float-right tanggal-cetak">
                                <i>Tanggal Cetak { Moment(date).format('DD-MM-YYYY')}</i>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="body">
                    <Table className='table-small table-bordered table-center table-laporan table-striped'>
                        <thead>
                            <tr>
                                <th>#</th>
                                { type === 'invoice'&&
                                    <th>Tanggal</th>
                                }
                                { type === 'invoice'&&
                                    <th>Nomor Invoice</th>
                                }
                                <th>Suplier</th>
                                <th>Saldo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listData.map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td className="text-right">{1+index}</td>
                                        { type === 'invoice'&&
                                            <td>{post.tanggal_display}</td>                                        
                                        }
                                        { type === 'invoice'&&
                                            <td>{post.detiltext}</td>                                        
                                        }
                                        <td>{post.kontak_display}</td>
                                        <td className='text-right'>{post.jumlah.toLocaleString()}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td className="text-right" colSpan={type === 'invoice' ? 4 : 2}>Total</td>
                                <td className="text-right">{totalHutang.toLocaleString()}</td>
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            </div>
        </div>
    )
}
export default PiutangCetak;