export function setLocalStorage(key, value) {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      // catch possible errors:
      // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
    }
}


export function getLocalStorage(key, initialValue) {
    try {
      const value = window.localStorage.getItem(key);
      return value ? JSON.parse(value) : initialValue;
    } catch (e) {
      // if error, return initial value
      return initialValue;
    }
}

export function wideScreen(){
  const el = document.getElementsByTagName('body')[0];
  el.classList.add('sidebar-minimized');          
  el.classList.add('brand-minimized');    

  const sd = document.getElementsByClassName('scrollbar-container')
  if (sd.length > 0){
      sd[0].classList.remove('ps')
      sd[0].classList.remove('ps-container')
      sd[0].classList.remove('ps--active-y')
  }

  const main = document.getElementsByTagName('main');
  if (main.length > 0){
      const breadcrum = main[0].childNodes[0];
      breadcrum.classList.add('hide')
  }
}