import React, { Fragment } from 'react';
import Barcode from 'react-barcode';

const ProdukBarcodePrint = () => {
    const myData = localStorage.getItem('dataProduk') ? JSON.parse(localStorage.getItem('dataProduk')) : null;        
    return(
        <Fragment>
            <div className="laporan-potrait">
                <div className="laporan">
                    <div className="title" style={{paddingLeft: 10, fontSize: 14, fontWeight: 'bold'}}>{myData && myData.nama ? myData.nama : ''}</div>
                    <div className="title" style={{paddingLeft: 10, fontSize: 14, fontWeight: 'bold'}}>Rp. {myData && myData.harga_jual ? myData.harga_jual.toLocaleString() : ''}</div>
                    <Barcode
                        value={myData && myData.barcode ? myData.barcode : ''}
                    />
                </div>
            </div>
        </Fragment>
    )
}
export default ProdukBarcodePrint;