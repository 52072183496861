import { useCallback, useEffect, useState } from "react";
import config from "../../../config";
import { useGlobalFuntction } from "../../../generalFunction/globalFunction";
import Moment from 'moment';
import api from "../../../services/api";
import * as XLSX from "xlsx";

export function useSalesByProduk(props){
    const fetchTipe = props && props.fetchTipe ? props.fetchTipe : 'parent';
    const { 
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, 
    } = useGlobalFuntction();
    const date = new Date();
    const [listData, setListData] = useState([]);
    
    
    const [formData, setFormData] = useState({
        outlet: '',
        outletObj : [],
        startDate : date,
        endDate : date,
        outlet_name : "Semua Outlet",
        total : 0,
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({...formData, [name] : value}));
        if (name === 'outlet'){
            let myOutlet = formData.outletObj.filter(x => parseInt(x.value) === parseInt(value));
            if (myOutlet.length > 0){
                setFormData(formData => ({...formData, outlet_name : myOutlet[0].label}))
            }
            fetchData(formData.startDate, formData.endDate, value);
        }
        
    }

    const fetchData = useCallback(async(startDate=null, endDate=null, outlet='') => {
        setIsLoading(true)
        try{
            let myDate = new Date()            
            let myStartDate = ''
            if (startDate){
                myStartDate = Moment(startDate).format('DD-MM-YYYY');
            }else{
                myStartDate = Moment(myDate).format('DD-MM-YYYY');
            }
            
            let myEndDate = '';
            if (endDate){
                myEndDate = Moment(endDate).format('DD-MM-YYYY');
            }else{
                myEndDate = Moment(myDate).format('DD-MM-YYYY');
            }
            const res = await api.get(`${config.endPoint.penjualanResumeByProduk}`, { params : {start_date: myStartDate, end_date: myEndDate, outlet: outlet}}).then(res => res.data);
            if (res){
                setListData(res.results);
                let myTotal = 0;
                res.results.map(post => myTotal += post.total);
                setFormData(formData => ({...formData, total: myTotal}))                
            }
            setIsLoading(false)
        }catch(error){
            setIsLoading(false)
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setIsLoading, setAlertValue])


    
    useEffect(() => {
        const outletList = localStorage.getItem(config.localStorageKey.OUTLET_LIST) ? JSON.parse(localStorage.getItem(config.localStorageKey.OUTLET_LIST)) : null
        if (outletList){
            let tmp = [{value : '', label : '-- Semua Outlet --'}]
            outletList.map(post => tmp.push({value : post.id, label : post.name}));
            setFormData(formData => ({...formData, outletObj : tmp}))
        }
    },[])

    const handleChangeDate = (e, name) => {
        setFormData(formData => ({...formData, [name] : e}));
        if (name === 'startDate'){
            fetchData(e, formData.endDate, formData.outlet)
        }else{
            fetchData(formData.startDate, e, formData.outlet)
        }
    }
    
    const handlePrint = () => {
        localStorage.removeItem('infoSalesByProduk');   
        let myFormData = {
            outlet : formData.outlet,
            startDate : Moment(formData.startDate).format('DD-MM-YYYY'),
            endDate: Moment(formData.endDate).format('DD-MM-YYYY')
        }
        localStorage.setItem('infoSalesByProduk', JSON.stringify(myFormData));           
        window.open(`#/laporan/cetak/resume-penjualan-byproduk`, "_blank")
    }


    useEffect(() => {
        if (fetchTipe === 'child'){
            fetchData();            
        }else{
            let myForm = JSON.parse(localStorage.getItem('infoSalesByProduk'));
            if (myForm){
                setFormData(formData => ({...formData,
                    startDate: Moment(myForm.startDate, 'DD-MM-YYYY').toDate(),
                    endDate: Moment(myForm.endDate, 'DD-MM-YYYY').toDate(),
                }));
                fetchData(Moment(myForm.startDate, 'DD-MM-YYYY').toDate(), Moment(myForm.endDate, 'DD-MM-YYYY').toDate(), myForm.outlet);
            }
            
        }        
    },[fetchTipe, fetchData])

    const handleExport = async() => {
        setIsLoading(true);
        try{
            let myStartDate = Moment(formData.startDate).format('DD-MM-YYYY')
            let myEndDate = Moment(formData.endDate).format('DD-MM-YYYY')
            const res = await await api.get(`${config.endPoint.penjualanResumeByProduk}`, { params : {start_date: myStartDate, end_date: myEndDate, outlet: formData.outlet}}).then(res => res.data);
            let tmp = [];
            


            var ws = XLSX.utils.aoa_to_sheet([
                ["Laporan Penjualan By Produk"],
                ["Nama Outlet", null, formData.outlet_name],
                ["Dari Tanggal", null,  myStartDate],
                ["s.d Tanggal", null, myEndDate],
                [""]
            ]);
            
            const header = [
                'Nomor',
                'Kode',
                'Nama Produk',
                'Kategori',
                'Jumlah',
                'Total',
            ]
            if (res){
                res.results.map((post, index) => {
                    tmp.push({
                        'Nomor' : index + 1,
                        'Kode': post.kode,
                        'Nama Produk' : post.produk_name,
                        'Kategori' : post.kategori,
                        'Jumlah' : post.jumlah,
                        'Total' : post.total,
                    });
                    return true;
                })
            }            
            // const worksheet = XLSX.utils.json_to_sheet(ws, tmp);
            XLSX.utils.sheet_add_json(ws, tmp, {header:header, origin:-1});
            const workbook = XLSX.utils.book_new();            
            XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
            //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
            //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
            XLSX.writeFile(workbook, "penjualan-byproduk.xlsx");
            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Unable to fetch Data Outlet', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }   
    }



    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, formData, handleChange, handleChangeDate, 
        listData, handlePrint, handleExport
    }
}