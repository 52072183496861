import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import StrukPrint from './views/Laporan/Cetak/StrukPrint';


// import './style/customStyle.css';

import { unregister } from './serviceWorker';
import LapPenerimaanCetak from './views/Laporan/Penerimaan/LapPenerimaanCetak';
import ResumeOutletComp from './views/Laporan/ResumeOutlet/ResumeOutletComp';
import SalesByProdukComp from './views/Laporan/SalesByProduk/SalesByProdukComp';
import ProdukBarcodePrint from './views/MasterData/Inventory/Produk/ProdukBarcodePrint';
import StrukMemberPrint from './views/Laporan/Cetak/StrukMemberPrint';
import StrukDepositePrint from './views/Laporan/Cetak/StrukDepositePrint';
import HutangCetak from './views/BackOffice/Hutang/HutangCetak';
import PiutangCetak from './views/BackOffice/Piutang/PiutangCetak';
import PengeluaranCetak from './views/BackOffice/Pengeluaran/PengeluaranCetak';
import StockOpenamePrint from './views/Laporan/Cetak/StockOpnamePrint';
import AccountResumePrint from './views/Accounting/Resume/AccountResumePrint';
import AccountDetailResumePrint from './views/Accounting/Resume/AccountDetailResumePrint';
import LabaRugiPrint from './views/Accounting/LabaRugi/LabaRugiPrint';
import NeracaPrint from './views/Accounting/Neraca/NeracaPrint';
import PegawaiCVPrint from './views/HRD/Pegawai/PegawaiCVPrint';
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const LoginPage = React.lazy(() => import('./views/Authentication/LoginPage'));
// const CetakMasterBill = React.lazy(() => import('./views/Room/Room/CetakMasterBill'));
unregister();

const App = () => {	
	return (		
		<HashRouter>
			<React.Suspense fallback={loading()}>
				<Switch>
					<Route exact path="/login" name="Login Page" render={props => <LoginPage {...props} />} />
					<Route exact path="/laporan/struk-penjualan" name="Struk Penjualan" render={props => <StrukPrint {...props} />} />
					<Route exact path="/laporan/cetak/penerimaan-kasir" name="Laporan Penerimaan Kasir" render={props => <LapPenerimaanCetak {...props} />} />					
					<Route exact path="/laporan/cetak/stock-opname" name="Stock Opname" render={props => <StockOpenamePrint {...props} />} />					
					<Route exact path="/laporan/cetak/resume-outlet" name="Laporan Resume Outlet" render={props => <ResumeOutletComp {...props} />} />					
					<Route exact path="/laporan/cetak/resume-account" name="Laporan Resume Account" render={props => <AccountResumePrint {...props} />} />
					<Route exact path="/laporan/cetak/resume-account-detail" name="Laporan Resume Account Detail" render={props => <AccountDetailResumePrint {...props} />} />
					<Route exact path="/laporan/cetak/laba-rugi" name="Laporan Laba Rugi" render={props => <LabaRugiPrint {...props} />} />					
					<Route exact path="/laporan/cetak/neraca" name="Neraca" render={props => <NeracaPrint {...props} />} />
					<Route exact path="/laporan/cetak/resume-penjualan-byproduk" name="Laporan Penjualan By Produk" render={props => <SalesByProdukComp {...props} />} />					
					<Route exact path="/laporan/cetak/hutang-cetak/:perusahaanParams/:type/" name="Laporan Hutang" render={props => <HutangCetak {...props} />} />					
					<Route exact path="/laporan/cetak/piutang-cetak/:perusahaanParams/:type/" name="Laporan Piutang" render={props => <PiutangCetak {...props} />} />					
					<Route exact path="/laporan/cetak/pengeluaran/:pengeluaranId/" name="Laporan Pengeluaran" render={props => <PengeluaranCetak {...props} />} />					
					<Route exact path="/setting/inventory/barcode-print" name="Cetak Barcode" render={props => <ProdukBarcodePrint {...props} />} />					
					<Route exact path="/laporan/struk-member" name="Cetak Penerimaan Member" render={props => <StrukMemberPrint {...props} />} />					
					<Route exact path="/laporan/struk-deposite" name="Cetak Deposite" render={props => <StrukDepositePrint {...props} />} />					
					<Route exact path="/pegawai/cv/:pegawaiId" name="CV Pegawai" render={props => <PegawaiCVPrint {...props} />} />					
					<Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
				</Switch>
			</React.Suspense>
		</HashRouter>		
	);
}

export default App;
