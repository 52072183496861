import React from 'react';
import { Col, Row, Table } from 'reactstrap';


const AccountDetailResumePrint = (props) => {
    let info = JSON.parse(localStorage.getItem('accountResume')) || null;
    return(
        <div className='laporan-lanscape' style={{background: '#ffffff'}}>
            {/* <div className='laporan'> */}

                <div className="header">
                    <Row>
                        <Col xs={12}>
                            <div className="text-center title">
                                Laporan Resume Account
                            </div>
                            <div className="text-center title">
                                { info && info.data && info.data.perusahanName}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className="text-center tanggal-cetak">
                                <i>Periode { info && info.data && info.data.startDate} s.d { info && info.data && info.data.startDate} </i>
                            </div>
                        </Col>
                    </Row>
                    <div style={{marginTop: 10}}/>
                    <Row>
                        <Col xs={6}>
                            <Row>
                                <Col xs={4}>Nomor Akun</Col>
                                <Col xs={8}>: { info && info.info && info.info.noakun }</Col>
                            </Row>
                            <Row>
                                <Col xs={4}>Nama Akun</Col>
                                <Col xs={8}>: { info && info.info && info.info.nmakun }</Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="body">
                    <Row>
                        <Col xs={12}>
                            <Table className='table-small table-bordered table-center table-laporan'>
                                <thead>
                                    <tr>
                                        <th rowSpan={2}>#</th>
                                        <th rowSpan={2}>Tanggal</th>
                                        <th rowSpan={2}>Nomor Group</th>
                                        <th rowSpan={2}>Diskripsi</th>
                                        <th colSpan={2}>Transaksi</th>
                                        <th rowSpan={2}>Balance</th>
                                    </tr>
                                    <tr>
                                        <th>D</th>
                                        <th>K</th>                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    { info && info.listJurnal.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td className="text-right">{index+1}</td>
                                                <td>{post.tglreport_display_date}</td>
                                                <td>{post.nogroup}</td>
                                                <td>{post.trxname}</td>
                                                <td className='text-right'>{post.ammdb && post.ammdb.toLocaleString()}</td>
                                                <td className='text-right'>{post.ammcr && post.ammcr.toLocaleString()}</td>
                                                <td className='text-right'>{post.saldo.toLocaleString()}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>                            
                            </Table>
                        </Col>
                    </Row>
                </div>
            {/* </div> */}
        </div>
    )
}
export default AccountDetailResumePrint;