import React, { Fragment } from 'react';
import { Col, Row, Table } from 'reactstrap';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import { useResumeOutlet } from './resumeOutletFunction';
import Moment from 'moment';

const DetailPage = (props) => {
    const { info, date, detail } = props;
    return(
        <Fragment>
            <div className="header">
                <Row>
                    <Col xs={12}>
                        <div className="text-center title">
                            Laporan Resume Outlet
                        </div>
                        <div className="text-center title">
                            { info && info.outlet_name}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="float-right tanggal-cetak">
                            <i>Tanggal Cetak { Moment(date).format('DD-MM-YYYY')}</i>
                        </div>
                    </Col>
                </Row>
                <Row className='info'>
                    <Col xs={6}>
                        <Row>
                            <Col xs={4}>Nama Outlet</Col>
                            <Col xs={8}>: { info && info.outlet_name }</Col>
                        </Row>
                        <Row>
                            <Col xs={4}>Tanggal Penerimaan</Col>
                            <Col xs={8}>: { info && info.tanggal }</Col>
                        </Row>
                    </Col>
                    <Col xs={6}>
                        <Row>
                            <Col xs={4}>Shift</Col>
                            <Col xs={8}>: { info && info.shift_name }</Col>
                        </Row>                    
                    </Col>
                </Row>
            </div>
            <div className="body">
                <Row>
                    <Col xs={12}>
                        <Table className='table-small table-bordered table-center table-laporan'>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>#</th>
                                    <th rowSpan={2}>Outlet</th>
                                    <th rowSpan={2}>Nomor Transaksi</th>
                                    <th rowSpan={2}>Konsumen</th>
                                    <th colSpan={6}>Tipe Pembayaran</th>
                                    <th rowSpan={2}>Total</th>
                                    <th rowSpan={2}>PIC</th>                                    
                                </tr>
                                <tr>
                                    <th>Cash</th>
                                    <th>Piutang</th>
                                    <th>Deposite</th>
                                    <th>Bank</th>
                                    <th>Debit / CC</th>
                                    <th>Emoney</th>
                                </tr>
                            </thead>
                            <tbody>
                                { detail.map((post, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className='text-right'>{index+1}</td>
                                            <td>{post.outlet_display}</td>
                                            <td>
                                                <div>{post.no_transaksi}</div>
                                                { post.bank_name &&
                                                    <div>{post.bank_name}</div>
                                                }
                                            </td>
                                            <td>{post.konsumen_name}</td>                                            
                                            <td className='text-right'>{post.cash_value.toLocaleString()}</td>
                                            <td className='text-right'>{post.piutang_value.toLocaleString()}</td>
                                            <td className='text-right'>{post.deposite_value.toLocaleString()}</td>
                                            <td className='text-right'>{post.bank_value.toLocaleString()}</td>
                                            <td className='text-right'>{post.debit_value.toLocaleString()}</td>
                                            <td className='text-right'>{post.emoney_value.toLocaleString()}</td>
                                            <td className='text-right'>{post.total.toLocaleString()}</td>
                                            <td className='text-right'>{post.pic_name}</td>
                                        </tr>
                                    )
                                })}
                                <tr className='bold'>
                                    <td className='text-right' colSpan={4}>Total</td>
                                    <td className="text-right">{info && info.cash ? info.cash.toLocaleString() : 0}</td>
                                    <td className="text-right">{info && info.piutang ? info.piutang.toLocaleString() : 0}</td>
                                    <td className="text-right">{info && info.deposite ? info.deposite.toLocaleString() : 0}</td>
                                    <td className="text-right">{info && info.bank ? info.bank.toLocaleString() : 0}</td>
                                    <td className="text-right">{info && info.debit ? info.debit.toLocaleString() : 0}</td>
                                    <td className="text-right">{info && info.emoney ? info.emoney.toLocaleString() : 0}</td>
                                    <td className="text-right">{info && info.total ? info.total.toLocaleString() : 0}</td>
                                    <td></td>
                                </tr>
                            </tbody>                            
                        </Table>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}


const ResumeOutletComp = (props) => {
    const { isLoading, info, detailLaporan } = useResumeOutlet({fetchTipe: 'detail'})
    const date = new Date();
    const tipe = props && props.tipe ? props.tipe : 'parent'
    const myInfo = tipe === 'child' ? props.info : info;
    const detail = tipe === 'child' ? props.detail : detailLaporan
    return(
        <Fragment>
            { tipe === 'parent' &&
                <div className="laporan-landscape">
                    <div className="laporan">
                        { isLoading && <LoaderComponent/> }
                        <DetailPage
                            date={date}
                            info={myInfo}
                            detail={detail}
                        />
                    </div>
                </div>
            }
            { tipe === 'child' &&            
                <div className="laporan">
                    { isLoading && <LoaderComponent/> }
                    <DetailPage
                        date={date}
                        info={myInfo}
                        detail={detail}
                    />
                </div>
            }
        </Fragment>
    )
}
export default ResumeOutletComp;