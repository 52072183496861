import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Col, Row, Table } from 'reactstrap';
import config from '../../../config';
import PerusahaanComp from '../../../containers/Components/PerusahaanComp';
import api from '../../../services/api';
// import * as penjualanService from '../../../services/penjualanService';

import '../../../style/strukStyle.scss';


const StrukMemberPrint = (props) => {
    const memberDetailId = localStorage.getItem('memberDetailId') || 0;
    const [memberInfo, setMemberInfo] = useState([]);    

    const fetchData = useCallback(async() => {
        try{
            const res = await api.get(`${config.endPoint.memberDetail}${memberDetailId}`).then(res => res.data);
            setMemberInfo(res.results);
        }catch(error){
            return;
        }
    },[memberDetailId])

    useEffect(() => {
        fetchData();
    },[fetchData])    
    
    

    return(
        <Fragment>
            <div className='strukprint-container'>
                { memberInfo && memberInfo.perusahaan &&
                    <PerusahaanComp
                        perusahaanId={memberInfo ? memberInfo.perusahaan : 0}
                    />
                }
                <div className="text-center title">BUKTI MEMBER</div>
                <div className="text-center desc" style={{marginBottom: '10px'}}>{memberInfo && memberInfo.member_display && memberInfo.member_display.nomember}</div>
                <Row>
                    <Col xs={12}>
                        <div className='text-center kasir-name'>Kasir : {memberInfo.pic_display}</div>
                        <div className='text-center tanggal-penjualan'>{memberInfo && memberInfo.tanggal_jam_transaksi_display}</div>
                    </Col>                    
                </Row>
                <div style={{borderBottom: '1px solid #000', marginBottom: '5px'}}></div>
                <div className="harga">
                    <div className='kasir-name'>Harga : { memberInfo && memberInfo.harga && memberInfo.harga.toLocaleString()}</div>
                </div>
                <div className="konsumen">
                    <div className='kasir-name'>Nama Konsumen : { memberInfo && memberInfo.member_display && memberInfo.member_display.konsumen_display && memberInfo.member_display.konsumen_display.nama}</div>
                </div>
                <div className="konsumen">
                    <div className='kasir-name'>Nama Paket : { memberInfo && memberInfo.nama_paket_member}</div>
                </div>
                <div className="konsumen">
                    <div className='kasir-name'>Tanggal Berlaku : { memberInfo && memberInfo.start_date_display}</div>
                </div>
                <div className="konsumen">
                    <div className='kasir-name'>Tanggal Berakhir : { memberInfo && memberInfo.end_date_display}</div>
                </div>
                <div style={{borderBottom: '1px solid #000', marginBottom: '5px'}}></div>
                <div className="text-center">
                    <div className='text-center kasir-name'>Ttd</div>
                    <div style={{marginTop: 45}}/>
                    <div className='text-center kasir-name'>Kasir : {memberInfo.pic_display}</div>
                </div>
                <Table>
                    <tbody>
                        { memberInfo.penjualan_detail && memberInfo.penjualan_detail.map((post, index) => {
                            return(
                                <Fragment key={index}>
                                    <tr>
                                        <td>{index+1}.</td>
                                        <td>{`${post.produk_display.nama.toUpperCase()}`}</td>
                                        <td>{`${post.qty} ${post.nama_satuan.toUpperCase()} x ${post.harga.toLocaleString()}`}</td>
                                        <td className='text-right'>{(parseInt(post.qty) * parseInt(post.harga)).toLocaleString()}</td>
                                    </tr>
                                    { post.diskon1 > 0 &&
                                        <tr>
                                            <td></td>
                                            <td>Diskon</td>
                                            <td></td>
                                            <td className='text-right'>{post.diskon1.toLocaleString()}</td>
                                        </tr>

                                    }                                    
                                </Fragment>
                            )
                        })}
                    </tbody>
                    
                </Table>                      
                
            </div>
        </Fragment>
    )
}
export default StrukMemberPrint