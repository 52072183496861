import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Col, Row, FormGroup, Table } from 'reactstrap';
import config from '../../../config';
import PerusahaanComp from '../../../containers/Components/PerusahaanComp';
import api from '../../../services/api';
// import * as penjualanService from '../../../services/penjualanService';

import '../../../style/strukStyle.scss';


const StrukPrint = (props) => {
    const penjualanId = localStorage.getItem('penjualanId') || 0;
    const [penjualan, setPenjualan] = useState([]);    

    const fetchData = useCallback(async() => {
        try{
            const res = await api.get(`${config.endPoint.penjualan}${penjualanId}`).then(res => res.data);
            setPenjualan(res.results);
        }catch(error){
            return;
        }
    },[penjualanId])

    useEffect(() => {
        fetchData();
    },[fetchData])    
    

    return(
        <Fragment>
            <div className='strukprint-container'>
                { penjualan && penjualan.perusahaan &&
                    <PerusahaanComp
                        perusahaanId={penjualan ? penjualan.perusahaan : 0}
                    />
                }
                <div className="text-center title">NOTA PENJUALAN</div>
                <div className="text-center desc" style={{marginBottom: '10px'}}>{penjualan && penjualan.no_transaksi}</div>
                <Row>
                    <Col className='col-lg-12'>
                        <div className='text-center kasir-name'>Kasir : {penjualan.pic_display}</div>
                        <div className='text-center tanggal-penjualan'>{penjualan && penjualan.tanggal_jam_display}</div>
                        <div className='text-center tanggal-penjualan'>Konsumen : {penjualan && penjualan.konsumen_display}</div>
                        <div className='text-center tanggal-penjualan'>Pembayaran : {penjualan && penjualan.jns_pembayaran_display} {penjualan && penjualan.bank_display ? `/ ${penjualan.bank_display.nama}` : ''}</div>
                    </Col>                    
                </Row>
                <div style={{borderBottom: '1px solid #000', marginBottom: '5px'}}></div>
                <Table>
                    <tbody>
                        { penjualan.penjualan_detail && penjualan.penjualan_detail.map((post, index) => {
                            return(
                                <Fragment key={index}>
                                    <tr>
                                        <td>{index+1}.</td>
                                        <td>{`${post.produk_display.nama.toUpperCase()}`}</td>
                                        <td>{`${post.qty} ${post.nama_satuan.toUpperCase()} x ${post.harga.toLocaleString()}`}</td>
                                        <td className='text-right'>{(parseInt(post.qty) * parseInt(post.harga)).toLocaleString()}</td>
                                    </tr>
                                    { post.diskon1 > 0 &&
                                        <tr>
                                            <td></td>
                                            <td>Diskon</td>
                                            <td></td>
                                            <td className='text-right'>{post.diskon1.toLocaleString()}</td>
                                        </tr>

                                    }                                    
                                </Fragment>
                            )
                        })}
                    </tbody>
                    <tfoot>
                        <tr className="tr-border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>                   
                        <tr className='tr-total'>
                            <td></td>
                            <td colSpan={2} className='text-right'>{(parseInt(penjualan.diskon1)+parseInt(penjualan.diskon2)) > 0 ? 'Subtotal' : 'Total'} :</td>
                            <td className='text-right'>{penjualan.total && (parseInt(penjualan.total)+parseInt(penjualan.diskon1)+parseInt(penjualan.diskon2)).toLocaleString()}</td>
                        </tr>
                        { (parseInt(penjualan.diskon1)+parseInt(penjualan.diskon2) > 0) && 
                            <Fragment>
                                <tr className='tr-total'>
                                    <td></td>
                                    <td  colSpan={2} className='text-right'>Diskon :</td>
                                    <td className='text-right'>-{penjualan && (parseInt(penjualan.diskon1)+parseInt(penjualan.diskon2)).toLocaleString()}</td>
                                </tr>
                                <tr className='tr-total'>
                                    <td></td>
                                    <td className='text-right' colSpan={2}>Total :</td>                                    
                                    <td className='text-right'>{penjualan.total && penjualan.total.toLocaleString()}</td>
                                </tr>
                            </Fragment>                           
                        }
                        { parseInt(penjualan.deposite) > 0 &&
                            <Fragment>
                                <tr className='tr-total'>
                                    <td colSpan={3} className='text-right'>Deposite :</td>
                                    <td className='text-right'>{penjualan.deposite.toLocaleString()}</td>
                                </tr>
                                <tr className='tr-total'>
                                    <td colSpan={3} className='text-right' style={{borderTop: '1px solid #000'}}>Saldo Deposite</td>
                                    <td className='text-right' style={{borderTop: '1px solid #000'}}>{(parseInt(penjualan.deposite) - parseInt(penjualan.total)).toLocaleString()}</td>
                                </tr>

                            </Fragment>
                        }
                        { parseInt(penjualan.jns_pembayaran) === 1 &&
                            <Fragment>
                                <tr className='tr-total'>
                                    <td colSpan={3} className='text-right'>Bayar eee : </td>
                                    <td className='text-right'>{penjualan.bayar && penjualan.bayar.toLocaleString()}</td>
                                </tr>
                                <tr className='tr-total'>
                                    <td colSpan={3} className='text-right'>Kembali :</td>
                                    { parseInt(penjualan.deposite) > 0 ?                                        
                                        // <td className='text-right'>{penjualan.bayar && (parseInt(penjualan.bayar) - parseInt(penjualan.total) - parseInt(penjualan.deposite)).toLocaleString()}</td>                                        
                                        // <td className='text-right'>{parseInt(penjualan.bayar) - parseInt(penjualan.deposite)}</td>
                                        <td className='text-right'>{penjualan.bayar && ((parseInt(penjualan.bayar) + parseInt(penjualan.deposite)) - parseInt(penjualan.total)).toLocaleString()}</td>
                                        :
                                        <td className='text-right'>{penjualan.bayar && (parseInt(penjualan.bayar) - parseInt(penjualan.total)).toLocaleString()}</td>
                                    }
                                </tr>

                            </Fragment>
                        }                  
                    </tfoot>
                </Table>                      
                { penjualan.tipe_pembayaran && parseInt(penjualan.tipe_pembayaran) === 3 &&
                    <Row>
                        <Col className='col-lg-12'>
                            <div className="float-right">
                                <div style={{fontWeight: 'bold', marginBottom: 10}} className='text-right'>Deposite</div>
                                <table style={{border:'none !important'}} className='table-deposite-struk'>
                                    <tbody  style={{border:'none !important'}}>
                                        <tr>
                                            <td>Deposite</td>
                                            <td>:</td>
                                            <td className='text-right'>{penjualan && penjualan.deposite.toLocaleString()}</td>
                                        </tr>
                                        <tr>
                                            <td style={{borderBottom: '1px solid #5e5e5e'}}>Terpakai</td>
                                            <td style={{borderBottom: '1px solid #5e5e5e'}}>:</td>
                                            <td style={{borderBottom: '1px solid #5e5e5e'}} className='text-right'>{penjualan && penjualan.total.toLocaleString()}</td>
                                        </tr>
                                        <tr>
                                            <td>Saldo Deposite</td>
                                            <td>:</td>
                                            <td className='text-right'>{penjualan && (parseInt(penjualan.deposite) - parseInt(penjualan.total)).toLocaleString()}</td>
                                        </tr>
                                    </tbody>
                                </table>    
                            </div>                        
                        </Col>
                    </Row>    
                }
                { penjualan.tipe_pembayaran && [2,3].includes(parseInt(penjualan.tipe_pembayaran)) &&
                    <Row>
                        <Col xs={6} className='offset-xs-6'>
                            <div style={{marginTop: 20}}/>
                            <FormGroup>
                                <div className='text-center'>
                                    { parseInt(penjualan.tipe_pembayaran) === 2 ? 'Penerima' : 'Konsumen' }
                                </div>
                                { parseInt(penjualan.tipe_pembayaran) === 2 &&
                                    <div className='text-center'> {penjualan.tipe_pembayaran_display} :</div>
                                }
                                <div className='text-center' style={{marginTop: 60}}>{penjualan.nama_pelanggan}</div>
                            </FormGroup>
                        </Col>
                    </Row>                
                }
                { penjualan.memo !== '' &&
                    <div className="memo">
                        <div className="memo-title">Memo :</div>
                        <div className="memo-description">
                            { penjualan.memo }
                        </div>
                    </div>
                }
            </div>
        </Fragment>
    )
}
export default StrukPrint