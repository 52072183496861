import React from "react"
import { Alert, Col, Row, Table } from "reactstrap"
import LoaderComponent from "../../../containers/Components/LoaderComponent"
import { useLapPenerimaan } from "./lapPenerimaanFunction"
import Moment from 'moment';

const LapPenerimaanComp = (props) => {    
    const { isLoading, detailLaporan, info, alertValue, handleCloseAlert } = useLapPenerimaan({fetchTipe: 'detail'})
    const date = new Date()
    const tipe = props && props.tipe ? props.tipe : 'parent'
    const detail = tipe === 'child' ? props.detailLaporan : detailLaporan
    return(
        <div className='laporan'>
            { isLoading && <LoaderComponent/>}
            <Alert isOpen={alertValue.show} color={alertValue.color} toggle={handleCloseAlert}>
                {alertValue.text}
            </Alert>
            <div className="header">                
                <Row>
                    <Col xs={12}>
                        <div className="text-center title">
                            Laporan Penerimaan Kasir
                        </div>
                        <div className="text-center title">
                            { tipe === 'child' ? props.info.outlet_name :   info && info.outlet_name ? info.outlet_name : ''}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="float-right tanggal-cetak">
                            <i>Tanggal Cetak { Moment(date).format('DD-MM-YYYY')}</i>
                        </div>
                    </Col>
                </Row>
                <Row className='info'>
                    <Col xs={6}>
                        <Row>
                            <Col xs={4}>Nama Outlet</Col>
                            <Col xs={8}>: { tipe === 'child' ? props.info.outlet_name : info && info.outlet_name ? info.outlet_name : ''}</Col>
                        </Row>
                        <Row>
                            <Col xs={4}>Tanggal Penerimaan</Col>
                            <Col xs={8}>: { tipe === 'child' ? props.info.tanggal :  info && info.tanggal ? info.tanggal : ''}</Col>
                        </Row>
                    </Col>
                    <Col xs={6}>
                        <Row>
                            <Col xs={4}>Shift</Col>
                            <Col xs={8}>: { tipe === 'child' ? props.info.shift_name : info && info.shift_name ? info.shift_name : ''}</Col>
                        </Row>                    
                    </Col>
                </Row>
            </div>
            <div className="body">

                <Row>
                    <Col xs={12}>
                        <Table className='table-small table-bordered table-center table-laporan table-striped'>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>#</th>
                                    <th rowSpan={2}>Tanggal</th>
                                    <th rowSpan={2}>Konsumen</th>
                                    <th rowSpan={2}>Diskripsi</th>
                                    <th rowSpan={2}>PIC</th>
                                    <th rowSpan={2}>Outlet</th>
                                    <th rowSpan={2}>Memo</th>
                                    <th colSpan={3}>Total</th>
                                </tr>
                                <tr>
                                    <th>Kas Outlet</th>
                                    <th>Other Payment</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                { detail.map((post, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className="text-right">{index+1}</td>
                                            <td>{post.tanggal_display}</td>
                                            <td>{post.konsumen_display}</td>
                                            <td>{post.trxname}</td>
                                            <td>{post.pic_display}</td>
                                            <td>{post.outlet_display}</td>
                                            <td>{post.memo}</td>
                                            <td className='text-right'>{post.total_kas.toLocaleString()}</td>
                                            <td className='text-right'>{post.total_non_kas.toLocaleString()}</td>
                                            <td className='text-right'>{post.total.toLocaleString()}</td>
                                        </tr>
                                    )
                                })}
                                <tr className='bold'>
                                    <td colSpan={7} className='text-right'>Total </td>
                                    <td className="text-right">{tipe === 'child' ? props.info.total_kas.toLocaleString() : info && info.total_kas ? info.total_kas.toLocaleString() : 0}</td>
                                    <td className="text-right">{tipe === 'child' ? props.info.total_non_kas.toLocaleString() : info && info.total_non_kas ? info.total_non_kas.toLocaleString() : 0}</td>
                                    <td className="text-right">{tipe === 'child' ? props.info.total.toLocaleString() : info && info.total ? info.total.toLocaleString() : 0}</td>
                                </tr>
                            </tbody>                            
                        </Table>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default LapPenerimaanComp