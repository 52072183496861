import React, { Fragment } from 'react';
import { Alert, Col, Row, Table } from 'reactstrap';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import { useSalesByProduk } from './salesByProdukFunction';
import Moment from 'moment';

const MyContainer = (props) => {
    const { info, date, detail } = props;
    return(
        <Fragment>
            
            <div className="header">                
                <Row>
                    <Col xs={12}>
                        <div className="text-center title">
                            Laporan Penjualan By Produk
                        </div>
                        <div className="text-center title">
                            { info &&  info.outlet_name ? info.outlet_name : ''}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="float-right tanggal-cetak">
                            <i>Tanggal Cetak { Moment(date).format('DD-MM-YYYY')}</i>
                        </div>
                    </Col>
                </Row>
                <Row className='info'>
                    <Col xs={6}>
                        <Row>
                            <Col xs={4}>Nama Outlet</Col>
                            <Col xs={8}>: { info &&  info.outlet_name ? info.outlet_name : ''}</Col>
                        </Row>                        
                    </Col>
                    <Col xs={12}>
                        <Row>
                            <Col xs={6}>
                                <Row>
                                <Col xs={4}>Dari Tanggal</Col>
                                    <Col xs={8}>: { info && info.startDate ? Moment(info.startDate).format('DD-MM-YYYY') : '' }</Col>
                                </Row>
                            </Col>
                            <Col xs={6}>
                                <Row>
                                    <Col xs={4}>s.d Tanggal</Col>
                                    <Col xs={8}>: { info && info.endDate ? Moment(info.endDate).format('DD-MM-YYYY') : '' } </Col>
                                </Row>                    
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="body">
                    <Row>
                        <Col xs={12}>
                            <Table className='table-small table-bordered table-center table-laporan table-striped'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Kode</th>
                                        <th>Nama Produk</th>
                                        <th>Kategori</th>
                                        <th>Jumlah</th>
                                        <th>Total</th>                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    { detail.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td className="text-right">{index+1}</td>
                                                <td>{post.kode}</td>
                                                <td>{post.produk_name}</td>
                                                <td>{post.kategori}</td>
                                                <td className='text-right'>{post.jumlah}</td>
                                                <td className='text-right'>{post.total.toLocaleString()}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td colSpan={5} className='text-right font-weight-bold'>Total</td>
                                        <td className="text-right font-weight-bold">{info && info.total ? info.total.toLocaleString()  : 0}</td>
                                    </tr>
                                </tbody>                                
                            </Table>
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    )
}

const SalesByProdukComp = (props) => {    
    const date = new Date()
    const tipe = props && props.tipe ? props.tipe : 'parent';
    const { isLoading, alertValue, handleCloseAlert, formData, listData } = useSalesByProduk({fetchTipe: tipe});
    
    const detail = tipe === 'child' ? props.detail : listData
    
    
    return(        
        <Fragment>
            { isLoading && <LoaderComponent/>}
            <Alert isOpen={alertValue.show} color={alertValue.color} toggle={handleCloseAlert}>
                {alertValue.text}
            </Alert>
            { tipe === 'child' ?
                <div className="laporan">
                    <MyContainer
                        info={tipe === 'child' ? props.info : formData}
                        date={date}
                        detail={detail}
                    />
                </div>
                :
                <div className="laporan-potrait">
                    <div className="laporan">
                        <MyContainer
                            info={tipe === 'child' ? props.info : formData}
                            date={date}
                            detail={detail}
                        />
                    </div>
                </div>
            }
        </Fragment>
    )
}
export default SalesByProdukComp