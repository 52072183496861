import React from 'react';
import Loader from 'react-loader-spinner';

const LoaderComponent = (props) => {
    return(
        <div style={{position:'fixed', top: window.innerHeight / 2, left: window.innerWidth/2, zIndex: 1055, justifyContent: 'center', alignItems:'center', ...props.style}}>
            <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={100}
                width={100}
            />          
        </div>   
    )
}

export default LoaderComponent;