import { useCallback, useEffect, useState } from "react";
import config from "../../../config";
import { useGlobalFuntction } from "../../../generalFunction/globalFunction";
import api from "../../../services/api";
import { pageObj } from "../../../utils/pageObj";
import Moment from 'moment';

export function usePiutang(props){
    const { 
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue,  fetchAccount, fetchProdukSatuan, getPermission,
    } = useGlobalFuntction();
    const [listData, setListData] = useState([]);
    const [info, setInfo] = useState(null);
    const fetchTipe = props && props.fetchTipe ? props.fetchTipe : null;
    const [listDetail, setListDetail] = useState([]);
    const [tipeView, setTipeView] = useState({
        obj : [{value: 'kreditur', label : 'Konsumen'}, {value : 'invoice', label : 'Invoice'}],
        value : 'kreditur',
    });
    const [modalLevel, setModalLevel] = useState({
        current : '',
        level : 0,
        post : null
    })
    const date = new Date()
    const [formData, setFormData] = useState({
        perusaahaan : {
            value : null,
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: false, 
            tipe: 'number', 
            label : 'Detail ID', 
            name : 'perusahaan',
            readOnly : true,
        },
        drtrx : {
            value : 0,
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: false, 
            tipe: 'number', 
            label : 'Detail ID', 
            name : 'drtrx',
            readOnly : true,
        },
        detilId : {
            value : 0,
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: false, 
            tipe: 'number', 
            label : 'Detail ID', 
            name : 'detilId',
            readOnly : true,
        },
        kontakId : {
            value : 0,
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: false, 
            tipe: 'number', 
            label : 'Kontak ID', 
            name : 'kontakId',
            readOnly : true,
        },
        nominal : {
            value : 0,
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'number', 
            label : 'Jumlah Yang akan dibayarkan', 
            name : 'nominal',
            className : 'text-right',
        },
        bank : {
            value : 0,
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select', 
            label : 'Bank', 
            name : 'bank',            
            obj : []
        },
        memo : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'textarea', 
            label : 'Memo', 
            name : 'memo',            
            obj : []
        },
        diskon : {
            value : 0,
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'number', 
            label : 'Diskon', 
            name : 'diskon',
            className : 'text-right',
        },
        tanggal : {
            value : date,
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'date', 
            label : 'Tanggal Bayar', 
            name : 'tanggal',
        }
    });
    const [filterKontak, setFilterKontak] = useState({
        value : '',
        obj : [
            {value : '', label : 'Semua Kontak'},
            {value : 'pegawai', label : 'Pegawai'},
        ]
    })
    // const [valueId, setValueId] = useState(0);

    const fetchData = useCallback(async(
        q='', 
        perusahaan='', 
        view='kreditur',
        with_saldo='yes', 
        offset=0,         
        limit=config.itemPerPage,
        kontak='') => {
        setIsLoading(true);
        try{            
            let res = await api.get(`${config.endPoint.piutang}?q=${q}&perusahaan=${perusahaan}&with_saldo=${with_saldo}&tipe_query=${view}`, { params : {limit : limit, offset: offset, tipe_kontak: kontak}}).then(res => res.data);
            if (res){                
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setAlertValue, setPageValue, setIsLoading])

    const handleChangeFilterKontak = (e) => {
        const { value } = e.target;
        setFilterKontak(filterKontak => ({...filterKontak, value: value}));
        setPageValue(pageValue => ({...pageValue, page: 1, offset: 0}));
        fetchData(pageValue.search, pageValue.perusahaan, tipeView.value, pageValue.status_active, 0, config.itemPerPage, value)
    }
    useEffect(() => {
        if (fetchTipe === null){
            fetchData();
        }
    },[fetchData, fetchTipe])

    useEffect(() => {
        if (fetchTipe === null){
            setPageValue(pageValue => ({...pageValue, status_active : 'yes'}))
        }
    },[setPageValue, fetchTipe])

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
        if (name === 'status_active'){
            fetchData(pageValue.search, pageValue.perusahaan, tipeView.value, value, 0, config.itemPerPage, filterKontak.value)
            setPageValue(pageValue => ({...pageValue,
                page: 1, offset: 0
            }));
        }
        if (name === 'perusahaan'){
            fetchData(pageValue.search, value, tipeView.value, pageValue.status_active,  0, config.itemPerPage, filterKontak.value)
            setPageValue(pageValue => ({...pageValue,
                page: 1, offset: 0
            }));            
        }
    }

    const handleSearch = () => {
        fetchData(pageValue.search, pageValue.perusahaan, tipeView.value, pageValue.status_active, 0, config.itemPerPage, filterKontak.value);
        setPageValue(pageValue => ({...pageValue,
            page: 1, offset: 0
        }));
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handlePagination = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage);
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}));
        fetchData(pageValue.search, pageValue.perusahaan, tipeView.value, pageValue.status_active, myOffset, config.itemPerPage, filterKontak.value)
    }

    const handleChangeView = (e) => {
        const { value } = e.target;
        setTipeView(tipeView => ({...tipeView, value : value}));
        fetchData(pageValue.search, pageValue.perusahaan, value, pageValue.status_active, 0, config.itemPerPage, filterKontak.value);
        setPageValue(pageValue => ({...pageValue, page : 1, offset: 0}))
    }

    const fetchBank = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.bank}`).then(res => res.data);
            let tmp = [];
            if (res){
                res.results.map(post => tmp.push({value : post.id, label : post.nama}));
            }
            setFormData(formData => ({...formData,                        
                bank : {...formData.bank, 
                    obj : tmp,
                    value : tmp.length > 0 ? tmp[0].value : ''
                },                
            }))
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch bank', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            },config.timeOutValue)
        }
    }, [setIsLoading, setAlertValue])

    useEffect(() => {
        if (fetchTipe === null){
            fetchBank();
        }
    },[fetchBank, fetchTipe])

    // ============================ detail ============================
    const handleCloseModal = () => {
        if (modalLevel.current !== ''){
            setModalValue(modalValue => ({...modalValue, tipe: modalLevel.current}));
            setModalLevel(modalLevel => ({...modalLevel, current: '', level : 0}))
            handleDetail(modalLevel.post, modalValue.content, modalLevel.current);
        }else{
            setModalValue(modalValue => ({...modalValue, show: false}))
        }
    }

    const handleDetail = async(post, content, tipeViewValue) => {        
        setListDetail([]);
        setFormData(formData => ({...formData,
            nominal : {...formData.nominal, value : 0},
            diskon : {...formData.diskon, value : 0},
            memo : {...formData.memo, value : 0},
        }))   
        let title;
        if (['invoice', 'invoiceClickByKontak'].includes(tipeViewValue)){
            title = "Detail Piutang by Invoice"
            setInfo(info => ({...info,
                nominal : post.jumlah,
                detilId : post.detilid,
                kontakId : post.kontak,
                saldoHutang : post.jumlah,
            }))
            fetchDetailInvoice(post.detilid)
            if (content === 'bayar'){                
                setFormData(formData => ({...formData,                        
                    detilId: {...formData.detilId, value : post.detilid, required : true},
                    kontakId: {...formData.kontakId, value : post.kontak},
                    drtrx : {...formData.drtrx, value : post.drtrx},
                    nominal : {...formData.nominal, readOnly: false},
                    perusahaan : {...formData.perusaahaan, value : post.perusahaan_id}
                }));
                title = "Terima Piutang"
            }
        }else{
            setInfo(info => ({...info,
                nominal : post.jumlah,
                detilId : 0,
                kontakId : post.kontak,                
            }))
            if (content === 'bayar'){                
                setFormData(formData => ({...formData,                        
                    detilId: {...formData.detilId, value : 0, required : false},
                    kontakId: {...formData.kontakId, value : post.kontak},
                    drtrx : {...formData.drtrx, value : post.drtrx},
                    nominal : {...formData.nominal, readOnly: true},
                    perusahaan : {...formData.perusaahaan, value : post.perusahaan_id}
                }));
                title = "Terima Piutang"
            }
            fetchDetailKontak(post.kontak);
            title = "Detail Piutang by Debitur"            
        };
        setModalValue(modalValue => ({...modalValue, show: true, tipe : tipeViewValue, title : title, content : content}));
    }

    const handleDetailFromKontak = async(post, content, tipeViewValue) => {
        setModalLevel(modalLevel => ({...modalLevel, current : 'kreditur', level: 1, post : post}));
        setModalValue(modalValue => ({...modalValue, tipe : 'invoiceClickByKontak'}))
        // setTipeView(tipeView => ({...tipeView, value : tipe}));
        handleDetail(post, content, tipeViewValue);
    }


    const hitungTotal = (array, diskon) => {
        let total = 0;
        array.map(post => {
            if (post.bayar !== ''){
                total += parseInt(post.bayar)
            }
            return true;
        });
        if (diskon !== ''){
            total = parseInt(total) - parseInt(diskon);
        }
        setFormData(formData => ({...formData, nominal : {...formData.nominal, value : total}}))
    }

    const fetchDetailInvoice = async(id=0) => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.jurnal}?detilid=${id}&type_query=piutang_by_invoice&nature_type=piutang`).then(res => res.data);
            if (res){
                if (res.results.length > 0){
                    let result = res.results[0]
                    setInfo(info => ({...info,
                        keterangan : result.trxname,
                        kreditur : result.kontak_display,
                        tanggal : result.tglreport_display_date,
                        nominal : result.ammdb
                    }))
                }                
                let tmp = [];
                let saldo = 0;                
                res.results.map(post => {
                    let obj = {...post}
                    saldo += (parseInt(post.ammdb) - parseInt(post.ammcr))
                    obj['saldo'] = saldo;
                    tmp.push(obj);
                    return true;
                })
                setListDetail(tmp);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : `Unable to fetch Detai Invoice`, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }
    
    const fetchDetailKontak = async(id=0, q='', offset=0, limit=config.itemPerPage) => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.piutang}?q=${q}&kontak_id=${id}&with_saldo=yes&tipe_query=invoice&tipe_view=detail`, { params : {limit : limit, offset: offset}}).then(res => res.data);                
            if (res){
                if (res.results.length > 0){
                    let result = res.results[0]
                    setInfo(info => ({...info,
                        keterangan : result.trxname,
                        kreditur : result.kontak_display,
                        tanggal : result.tanggal_display,
                    }))
                }
                let tmp = [];
                res.results.map(post => {
                    let obj = {...post};
                    obj['bayar'] = 0;
                    tmp.push(obj);
                    return true;
                })
                setListDetail(tmp);
            }
            setIsLoading(false);
            
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : `Unable to fetch Detai Invoice`, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        let nilai = value;
        if (formData[name].tipe === 'number'){
            nilai = nilai.replace(/,/g, '');
        }
        setFormData(formData => ({...formData,
            [name] : {...formData[name], value : nilai}
        }));
        if (tipeView.value === 'kreditur' && modalValue.content === 'bayar' && name === 'diskon'){
            hitungTotal(listDetail, nilai);
        }
    }

    const handleChangeDate = (e, name) => {
        setFormData(formData => ({...formData,
            [name] : {...formData[name], value : e}
        }))
    }
    
    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && (value === '' || value === 0)){
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : true}
            }))
        }else{
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : false}
            }))
        }
    }

    const handleSubmitInvoice = async() => {
        setIsLoading(true);
        try{
            let countError = 0;
            let objForm = {...formData};
            let tmpForm = {}

            Object.entries(objForm).map(([index, post]) => {
                if (post.required && (post.value === '' || post.value === 0)){
                    post.showError = true;
                    countError++;
                }else{
                    tmpForm[index] = post.value;
                }
                return true;
            })

            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi data', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                setIsLoading(false);
                return;
            }
            tmpForm['tanggal'] = Moment(tmpForm['tanggal']).format('YYYY-MM-DD HH:mm')

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            const res = await api.post(`${config.endPoint.piutangTerimaByInvoice}`, newForm).then(res => res.data);
            if (res){
                handleCloseModal();
                fetchData(pageValue.search, pageValue.perusahaan, tipeView.value, pageValue.status_active, pageValue.offset, config.itemPerPage, filterKontak.value)
            }            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let msg = "Unable to fetch Data"
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleSubmitByKontak = async() => {
        setIsLoading(true);
        try{
            let countError = 0;
            let objForm = {...formData};
            let tmpForm = {}

            Object.entries(objForm).map(([index, post]) => {
                if (post.required && (post.value === '' || post.value === 0)){
                    post.showError = true;
                    countError++;                    
                }else{
                    tmpForm[index] = post.value;
                }
                return true;
            });

            let tmpDetail = [];
            listDetail.map(post => {
                let obj = {}
                obj['detilid'] = post.detilid;
                obj['detiltext'] = post.detiltext;
                obj['drtrx'] = post.drtrx;
                if (post.bayar === ''){
                    obj['bayar'] = 0;
                }else{
                    obj['bayar'] = post.bayar;
                }
                obj['perusahaan_id'] = post.perusahaan_id

                tmpDetail.push(obj);
                return true;
            })


            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi data', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                setIsLoading(false);
                return;
            }

            if (parseInt(tmpForm['nominal']) < 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Diskon tidak boleh melebih dari jumlah pembayaran', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                setIsLoading(false);
                return;
            }
            tmpForm['tanggal'] = Moment(tmpForm['tanggal']).format('YYYY-MM-DD HH:mm')

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            newForm.append('listDetail', JSON.stringify(tmpDetail));
            const res = await api.post(`${config.endPoint.piutangTerimaByKontak}`, newForm).then(res => res.data);
            if (res){
                handleCloseModal();
                fetchData(pageValue.search, pageValue.perusahaan, tipeView.value, pageValue.status_active, pageValue.offset, config.itemPerPage, filterKontak.value)
            }            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let msg = "Unable to fetch Data"
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleChangeBayar = (e, index) => {
        const {  value } = e.target;
        let array = [...listDetail];        
        let nilai = value;
        nilai = nilai.replace(/,/g, '');
        array[index] = {...array[index], bayar : nilai}
        setListDetail(array);
        hitungTotal(array, formData.diskon.value);
    }

     // ======================================== create ===========================================
     const [formCreate, setFormCreate] = useState({
        perusahaan : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select', 
            label : 'Perusahaan', 
            name : 'perusahaan',
            obj : [],
        },
        tanggal : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'date',
            label : 'Tanggal', 
            name : 'tanggal',
        },
        akun_debet : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select_group',
            label : 'Akun Debet', 
            name : 'akun_debet',
            obj: []
        },
        tipe_jurnal : {
            value : 'jurnal_normal',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select', 
            label : 'Tipe Jurnal', 
            name : 'tipe_jurnal',
            // obj : [{value : 'jurnal_normal', label : 'Jurnal Normal'}, {value : 'open_balance', label : 'Open Balance'}],
            obj : [{value : 'jurnal_normal', label : 'Jurnal Normal'}],
        },
        akun_kredit : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select_group',
            label : 'Akun Debet', 
            name : 'akun_kredit',
            obj: []
        },
        debitur_display : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'input_button',
            label : 'Debitur', 
            name : 'debitur',
            onClick: function(){
                handleShowKontak()
            },
            readOnly: true,
        },
        debitur : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: false, 
            tipe: 'text',
            label : 'debitur', 
            name : 'debitur',            
            readOnly: true,
        },
        keterangan : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true,
            tipe: 'text',
            label : 'Keterangan',
            name : 'keterangan',
        },
        nominal : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true,
            tipe: 'number',
            label : 'nominal',
            name : 'nominal',
            className: 'text-right',
        },

    });

    const handleAdd = async() => {
        setModalValue(modalValue => ({...modalValue,
            show: true, tipe : 'create', title: 'Tambah Piutang Baru'
        }));
        
        // ============== kredit =================
        let tmpAccountHutang;
        tmpAccountHutang = await fetchAccount();        
        let tmpResAccountHutang = [];
        tmpAccountHutang.map(parent => {
            let tmpChild = []
            parent.child.map(child => {
                tmpChild.push({value : child.id, label : `${child.noakun} - ${child.nmakun}`})
                return true;
            });
            tmpResAccountHutang.push({
                label : `${parent.noakun} - ${parent.nmakun}`,
                value : parent.id,
                child : tmpChild
            })
            return true;
        });

        // =============== debet ================
        let tmpAccountDebet;
        tmpAccountDebet = await fetchAccount('Piutang');        
        let tmpResAccountDebet = [];

        tmpAccountDebet.map(parent => {
            let tmpChild = []
            parent.child.map(child => {
                tmpChild.push({value : child.id, label : `${child.noakun} - ${child.nmakun}`})
                return true;
            });
            tmpResAccountDebet.push({
                label : `${parent.noakun} - ${parent.nmakun}`,
                value : parent.id,
                child : tmpChild
            })
            return true;
        });



        setFormCreate(formCreate => ({...formCreate,
            akun_kredit : {...formCreate.akun_kredit, obj : tmpResAccountHutang},
            akun_debet : {...formCreate.akun_debet, obj : tmpResAccountDebet},
        }))
    }

    const handleChangeCreate = (e) => {
        const { name, value } = e.target;
        let nilai = value;
        if (formCreate[name].tipe === 'number'){
            nilai = nilai.replace(/,/g, '');
        }
        setFormCreate(formCreate => ({...formCreate,
            [name] : {...formCreate[name], value : nilai}
        }))
    }
    
    const handleChangeDateCreate = (e, name) => {
        setFormCreate(formCreate => ({...formCreate,
            [name] : {...formCreate[name], value : e}
        }))
    }

    const handleShowKontak = () => {
        setModalValue(modalValue => ({...modalValue,
            show: true, tipe : 'kontak', title: 'Daftar Kontak'
        }))
    }

    const handleCloseModalKontak = () => {
        setModalValue(modalValue => ({...modalValue,
            show: true, tipe : 'create'
        }))
    }

    const handleClickKontak = (post) => {
        setFormCreate(formCreate => ({...formCreate,
            debitur_display : {...formCreate.debitur_display, value : post.nama},
            debitur : {...formCreate.debitur, value : post.id},
        }));
        handleCloseModalKontak();
    }

    const fetchPerusahaan = useCallback(() => {
        setFormCreate(formCreate => ({...formCreate, perusahaan : {...formCreate.perusahaan, obj : pageValue.perusahaanObj, value : pageValue.perusahaanObj.length === 1 ? pageValue.perusahaanObj[0].value : ''}}))
    },[pageValue.perusahaanObj])

    useEffect(() => {
        if (fetchTipe === null){
            fetchPerusahaan();
        }
    },[fetchPerusahaan, fetchTipe])
    

    const handleSubmitCreate = async() => {
        setIsLoading(true);        
        try{
            let array = {...formCreate};
            let tmpForm = {}
            let countError = 0;

            Object.entries(array).map(([index, post]) => {
                if (post.required && post.value === ''){
                    post.showError = true;
                    countError++;
                }else{
                    tmpForm[index] = post.value;
                }
                return true;
            })

            if (countError > 0){
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                return;
            }

            tmpForm['tanggal'] = Moment(tmpForm['tanggal']).format('YYYY-MM-DD');
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));

            const res = await api.post(`${config.endPoint.piutang}`, newForm).then(res => res.data);            
            if (res){
                handleCloseModal();
                fetchData('', pageValue.perusahaan, tipeView.value, pageValue.status_active, 0, config.itemPerPage, filterKontak.value);
                setPageValue(pageValue => ({...pageValue, search: '', offset: 0, page: 1}))
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let msg = "Unable to fetch Data"
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)    
        }
    }
    

    // ===================================== cetak ===================================
    const propsView = props && props.view ? props.view : 'invoice';
    const propsPerusahaan = props && props.perusahaan ? props.perusahaan : '';
    
    const [perusahaan, setPerusahaan] = useState('');
    const [totalHutang, setTotalHutang] = useState(0);
    const handlePrint = () => {        
        window.open(`#/laporan/cetak/piutang-cetak/${pageValue.perusahaan}/${tipeView.value}/`, "_blank")
    }

    const fetchDataPrint = useCallback(async(
        perusahaan='',        
        with_saldo='yes'
    ) => {
        setIsLoading(true);
        try{            
            let res = await api.get(`${config.endPoint.piutang}?perusahaan=${perusahaan}&with_saldo=${with_saldo}&tipe_query=${propsView}`).then(res => res.data);
            if (res){                        
                setListData(res.results);
                let tmpTotal = 0;
                res.results.map(post => {
                    tmpTotal += post.jumlah
                    return true;
                })
                setTotalHutang(tmpTotal);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);            
        }
    },[propsView, setIsLoading])

    const fetchPerusahaanPrint = useCallback(async() => {        
        setIsLoading(true);        
        try{
            const res = await api.get(`${config.endPoint.perusahaan}${propsPerusahaan}/`).then(res => res.data);            
            if (res){
                setPerusahaan(res.results.nama)
            }            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: false, text : 'Unable to fetch perusahaan', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setIsLoading, setAlertValue, propsPerusahaan])

    useEffect(() => {
        if (fetchTipe === 'print'){
            fetchDataPrint();
            fetchPerusahaanPrint();
        }
    },[fetchTipe, fetchDataPrint, fetchPerusahaanPrint])

    const handleUnposting = (post) => {
        handleCloseAlert();
        setModalValue(modalValue => ({...modalValue,
            show: true,
            id : post.detilid,
            text : `Apakah anda yakin akan menghapus piutang ${post.trxname} an. ${post.kontak_display} sebesar Rp.${post.jumlah.toLocaleString()}?`,
            drtrx : post.drtrx,
            tipe: 'unposting',
            params : {
                drtrx : post.drtrx,
                kontak_id : post.kontak
            },
        }))
    }

    const handleSubmitUnposting = async() => {
        setIsLoading(true);
        try{            
            let data = modalValue.params
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(data))
            const res = await api.post(`${config.endPoint.unpostingPiutang}${modalValue.id}/`, newForm).then(res => res.data);
            if (res){
                handleCloseModal();
                fetchData();
            }            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let msg = "Unable to fetch Data"
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }


    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, fetchAccount, fetchProdukSatuan, getPermission,
        listData, handleChangeSearch, handleSearch, handleKeyDownSearch, handlePagination,
        tipeView, handleChangeView, handleDetail, info, listDetail, handleChange, handleBlur, formData,
        handleSubmitInvoice, handleChangeBayar, handleSubmitByKontak, handleDetailFromKontak, handleAdd,
        handleChangeDateCreate, handleChangeCreate, formCreate, handleCloseModalKontak, handleClickKontak,
        handleSubmitCreate, totalHutang, handlePrint, perusahaan, handleUnposting, handleSubmitUnposting,
        handleChangeDate, handleChangeFilterKontak, filterKontak
    }
}