import React, { useState, useEffect, useCallback } from 'react';
import config from '../config';
import * as ut from '../utils/utils';
const UserContext = React.createContext([{}, () => {}]);


  
const UserProvider = (props) => {      
    const [state, setState] = useState({
        listPenjualan : ut.getLocalStorage("listPenjualan", []),
        listOutlet : []
    });

    useEffect(() => {
        ut.setLocalStorage("listPenjualan", state.listPenjualan);        
    }, [state.listPenjualan]);

    const setListPenjualanValue = useCallback(() => {
        // ut.setLocalStorage("listPenjualan", post);        
        const myData = ut.getLocalStorage('listPenjualan');
        setState(state => ({...state, listPenjualan : myData}))
    },[]);
    
    const restoreOutlet = useCallback(() => {
        // const myOutlet = localStorage.getItem(config.localStorageKey.OUTLET_LIST)
        const myOutlet = ut.getLocalStorage(config.localStorageKey.OUTLET_LIST, []);
        let tmp = [];        
        myOutlet.map(post => {            
            tmp.push({label : post.name, value : post.id})
            return true;
        })
        setState(state => ({...state, listOutlet: tmp}))        
    },[])

    const stateFunction = {
        setListPenjualanValue : setListPenjualanValue,
    }

    useEffect(() => {
        restoreOutlet();
    },[restoreOutlet])


  

    return (
        <UserContext.Provider value={{            
            stateContext: [state, setState], 
            stateFunction: stateFunction,
        }}>
            {props.children}
        </UserContext.Provider>
    )
}
export { UserContext, UserProvider};
