import { useCallback, useEffect, useState } from "react";
import config from "../../../config";
import { useGlobalFuntction } from "../../../generalFunction/globalFunction";
import api from "../../../services/api";
import Moment from 'moment';
import * as XLSX from "xlsx";

export function useLapPenerimaan(props){
    const { 
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, 
    } = useGlobalFuntction();
    const date = new Date();
    const fetchTipe = props && props.fetchTipe ? props.fetchTipe : '';
    const [formData, setFormData] = useState({
        outlet : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select', 
            label : 'Outlet', 
            name : 'outlet',
            readOnly : false,
            obj : []
        },
        tanggal : {
            value : date,
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'date', 
            label : 'Tanggal', 
            name : 'tanggal',
            readOnly : false,
        },
        shift : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select', 
            label : 'Shift', 
            name : 'shift',
            readOnly : false,
            obj : []
        },
    })
    
    const fetchShift = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.shift}`).then(res => res.data);
            let tmp = [{value : '', label : '-- Semua Shift --'}]
            if (res){   
                res.results.map(post => tmp.push({value: post.id, label : post.nama}))
            }
            setFormData(formData => ({...formData,
                shift : {...formData.shift, obj : tmp}
            }));
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Unable to fetch Data Shift', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setAlertValue, setIsLoading]);

    useEffect(() => {
        if (fetchTipe === 'header'){
            fetchShift();
        }
    },[fetchShift, fetchTipe])

    const fetchOutlet = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.masterOutlet}`).then(res => res.data);
            let tmp = [{value : '', label : '-- Semua Outlet --'}]
            if (res){   
                res.results.map(post => tmp.push({value: post.id, label : post.nama}))
            }
            setFormData(formData => ({...formData,
                outlet : {...formData.outlet, obj : tmp}
            }));
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Unable to fetch Data Outlet', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setAlertValue, setIsLoading]);

    useEffect(() => {
        if (fetchTipe === 'header'){
            fetchOutlet();            
        }
    },[fetchOutlet, fetchTipe])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({...formData, [name] : {...formData[name], value : value}}));
        
        let outlet = formData.outlet.value
        let shift = formData.shift.value
        if (name === 'shift'){
            shift = value;
            if (value !== ''){
                fetchDetailShift(value);
            }else{
                setInfo(info => ({...info, shift_id: '', shift_name: 'Semua Shift'}))
            }
        }
        if (name === 'outlet'){
            if (value !== ''){
                fetchDetailOutlet(value);
            }else{
                setInfo(info => ({...info, outlet_id: '', outlet_name: 'Semua Outlet'}))
            }
            outlet = value;
        }
        localStorage.removeItem('dataLaporanPenerimaan')
        let tmp = {
            tanggal : Moment(formData.tanggal.value).format('YYYY-MM-DD'),
            outlet : outlet,
            shift : shift
        }
        localStorage.setItem('dataLaporanPenerimaan', JSON.stringify(tmp));
        fetchDetailLaporan(tmp.tanggal, shift, outlet);
    }
    
    const handleChangeDate = (e, name) => {
        setFormData(formData => ({...formData, [name] : {...formData[name], value : e}}));

        let outlet = formData.outlet.value
        let shift = formData.shift.value        
        localStorage.removeItem('dataLaporanPenerimaan')
        let tmp = {
            tanggal : Moment(e).format('YYYY-MM-DD'),
            outlet : outlet,
            shift : shift
        }
        localStorage.setItem('dataLaporanPenerimaan', JSON.stringify(tmp))
        fetchDetailLaporan(tmp.tanggal, shift, outlet);
    }
    
    const handlePrint = () => {
        window.open(`#/laporan/cetak/penerimaan-kasir`, "_blank")
    }

    // ========================== cetak =========================
    const [info, setInfo] = useState({
        outlet_id : '',
        outlet_name : "Semua Outlet",
        tanggal : Moment(date).format('YYYY-MM-DD'),
        shift_id : '',
        shift_name : "Semua Shift",
        total : 0,
        total_kas : 0,
        total_non_kas : 0,
    });
    const [detailLaporan, setDetailLaporan] = useState([]);

    const fetchDetailLaporan = useCallback(async(tanggal='', shift='', outlet='') => {
        setIsLoading(true);
        try{            
            const res = await api.get(`${config.endPoint.laporanPenerimaanKasir}?tanggal=${tanggal}&shift=${shift}&outlet=${outlet}&jenis_pembayaran=cash&status_posting=posting`).then(res => res.data);
            let total = 0;
            let total_kas = 0;
            let total_non_kas = 0;
            let tmp = [];
            
            if (res){
                res.results.map(post => {
                    tmp.push(post);
                    total += parseInt(post.total);
                    total_kas += parseInt(post.total_kas);
                    total_non_kas += parseInt(post.total_non_kas);
                    return true;
                })
            }            
            setInfo(info => ({...info, 
                total : total,
                total_kas : total_kas,
                total_non_kas : total_non_kas
            }));
            setDetailLaporan(tmp);
            if (tanggal !== ''){
                let myTanggal = Moment(tanggal, 'YYYY-MM-DD').toDate()
                myTanggal = Moment(myTanggal).format('DD-MM-YYYY');
                setInfo(info => ({...info, tanggal : myTanggal}))
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Unable to fetch Data Outlet', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setAlertValue, setIsLoading])

    const fetchDetailOutlet = useCallback(async(id=0) => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.masterOutlet}${id}/`).then(res => res.data);
            if (res){
                setInfo(info => ({...info,
                    outlet_name : res.results.nama,
                    outlet_id : res.results.id
                }))
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Unable to fetch Data Outlet', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setIsLoading, setAlertValue])
    
    const fetchDetailShift = useCallback(async(id=0) => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.shift}${id}/`).then(res => res.data);
            if (res){
                setInfo(info => ({...info,
                    shift_name : res.results.nama,
                    shift_id : res.results.id
                }))
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Unable to fetch Data Outlet', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setIsLoading, setAlertValue])


    useEffect(() => {        
        let myTanggal = new Date()
        let tanggal = Moment(myTanggal).format('YYYY-MM-DD')
        let shift = '';
        let outlet = '';
        if (fetchTipe === 'detail'){
            const dataLocal = localStorage.getItem('dataLaporanPenerimaan') || null;
            // let tanggal = ''
            
            
            if (dataLocal){           
                let resDataLocal = JSON.parse(dataLocal);
                tanggal = resDataLocal.tanggal;
                outlet  = resDataLocal.outlet;
                shift = resDataLocal.shift;            
            }
            fetchDetailLaporan(tanggal, shift, outlet);

            if (shift !== ''){
                fetchDetailShift(shift);
            }
            if (outlet !== ''){
                fetchDetailOutlet(outlet);
            }            
        }else if(fetchTipe === 'header'){
            fetchDetailLaporan(tanggal, shift, outlet);
        }
    },[fetchDetailLaporan, fetchTipe, fetchDetailShift, fetchDetailOutlet]);


    const samplejson2 = [
        { name: "name01" , age:"20",sex:"M" },
        { name: "name02" , age:"22",sex:"F" },
        { name: "name03" , age:"20",sex:"M" }
    ];

    const handleExport = async() => {
        setIsLoading(true);
        try{
            let tanggal = Moment(formData.tanggal.value).format('YYYY-MM-DD')
            const res = await api.get(`${config.endPoint.laporanPenerimaanKasir}?tanggal=${tanggal}&shift=${formData.shift.value}&outlet=${formData.outlet.value}&jenis_pembayaran=cash&status_posting=posting`).then(res => res.data);
            let tmp = [];            

            var ws = XLSX.utils.aoa_to_sheet([
                ["Laporan Penerimaan Kasir"],
                ["Nama Outlet", null,  info.outlet_name],
                ["Tanggal",  null, info.tanggal],
                ["Shift",  null, info.shift_name],
                [""]
            ]);
            
            const header = [
                'Nomor',
                'Tanggal',
                'Konsumen',
                'Deskripsi',
                'Pic',
                'Outlet',
                'memo',
                'total'
            ]
            if (res){
                res.results.map((post, index) => {
                    tmp.push({
                        'Nomor' : index+1,
                        'Tanggal' : post.tanggal_display,
                        'Konsumen' : post.konsumen_display,
                        'Deskripsi' : post.trxname,
                        'Pic' : post.pic_display,
                        'Outlet' : post.outlet_display,
                        'memo' : post.memo,
                        'total' : post.total
                    });
                    return true;
                })
            }            
            // const worksheet = XLSX.utils.json_to_sheet(ws, tmp);
            XLSX.utils.sheet_add_json(ws, tmp, {header:header, origin:-1});
            const workbook = XLSX.utils.book_new();            
            XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
            //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
            //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
            XLSX.writeFile(workbook, "penerimaan-kasir.xlsx");
            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Unable to fetch Data Outlet', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }

        
    }
    
    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, formData, handleChange, handleChangeDate, info, detailLaporan,
        handlePrint, handleExport, samplejson2
    }
}